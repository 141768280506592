import BaseModelAPI from "../BaseModelAPI";
import { API_INTEGRATION_MODEL } from "./const";

class IntegrationApiRequest extends BaseModelAPI {
    constructor() {
        super(API_INTEGRATION_MODEL.url);
    }

}

export default IntegrationApiRequest;
