import BaseModelAPI from "../BaseModelAPI";
import apiConfig from "../apiConfig";
import { API_COMMENTS_MODEL } from "./const";

class CommentsApiRequest extends BaseModelAPI {
    constructor() {
        super(API_COMMENTS_MODEL.url,  apiConfig.baseUrlUser);
    }
}

export default CommentsApiRequest;
