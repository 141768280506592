import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setDataPressItem } from '../../store/microdistDataReduces';
import FormInput from '../FormInput/FormInput';
import { fieldToArray } from '../UI/functions/functions';
import './styles.scss'
import Buttons from '../Buttons/Buttons';
import icons from '../../assets/icons/icons';
import LandPlotApiRequest from '../../api/Landplot/LandPlot';
import { useParams } from 'react-router-dom';
import { setNeedsRerender } from '../../store/needsRerenderSlice';
import ErrorMessage from '../UI/ErrorMassage/ErrorMassage';
import RightSidebar from '../RightSidebar/RightSidebar';
import { updateDataKey } from '../UI/functions/updateDataKey/updateDataKey';
import Checkbox from '../Checkbox/Checkbox';
import FormBlock from '../FormBlock/FormBlock';

const LandploatFromControl = ({ controlData, controlOptionBonusDocDate, controlOptionComplaint, controlOptionFoundation, controlOptionExploitationt, type, isRedact, isDontRedact }) => {
    const dispatch = useDispatch()
        , microdistData = useSelector((state) => state.mocrodist_data)
        , needsRerender = useSelector((state) => state.needsRerender)
        , [formError, setFormError] = useState('')
        , [isComplete, setIsComplete] = useState(false)
        , landPlot = new LandPlotApiRequest()
        , { id } = useParams()

        , [exploitationAgreements, setExploitationAgreements] = useState([])
        , [foundationAgreements, setFoundationAgreements] = useState([])
        , classDict = {
            'agreement_date': '',
            'extension_date': '',
            'files': 'col-3',
        };

    const handleChangeInput = (field, value, identifier) => {
        const fields = [
            'last_update_datetime',
            'planned_completion_date',
            'supplementary_agreements'
        ]

        const updatedData = { ...microdistData?.control_building_tab };

        if (identifier === 'exploitation' || identifier === 'foundation') {
            if (fields.includes(field)) {
                const prevData = { ...updatedData[identifier] };
                updateDataKey(prevData, [field], value === '' ? null : value);
                updatedData[identifier] = prevData;
            }
        } else {
            if (identifier === 'complaint') {
                if (!updatedData[identifier]) {
                    updatedData[identifier] = {}
                } else {
                    // updatedData[identifier] указатель на этот словарь, ссылается на неизменяемые данные
                    // (в redux store скорее всего), из-за этого возникает ошибка при его изменение,
                    // поэтому надо его содержимое скопировать в новый словарь
                    updatedData[identifier] = {...updatedData[identifier]}
                }
                updatedData[identifier][field] = value === '' ? null : value;
            } else {
                updateDataKey(updatedData, [field], value === '' ? null : value);
            }
        }

        dispatch(setDataPressItem(['control_building_tab', updatedData]));
    };

    const classContain = {
        'passport': 'col-1',
        'phone': 'col-1',
        'number': 'col-1',
        'birth_date': 'col-2',
        'email': 'col-2',
        'bonus_doc_date': 'col-2',
    }

    const offVisibleInputKey = [
        'id',
        'tab',
        'engineering_networks_payments',
        'planned_waste_disposal_payments',
        'installment_for_plot_payments',
        'waste_disposal_payments',
        'remaining_payments',
        'initial_payments',
    ]

    // Функция для рендеринга полей формы
    const renderFormInputs = (dataOptions, identifier) => {

        return dataOptions.map((item, index) => {
            const key = item.key;
            let label = item?.value?.label
            let type = item?.value?.type
            let options = item?.value?.choices || []
            let disabled = false
            let textArea = false
            if (offVisibleInputKey.includes(key)) {
                return null;
            }

            let foundValue = null;

            const value = microdistData?.control_building_tab?.[identifier];
            if (value !== undefined) {
                foundValue = value;
            }

            switch (identifier) {
                case 'exploitation':
                    disabled = true
                    switch (item.key) {
                        case 'planned_completion_date':
                            label = 'Эксплуатация (план)'
                            type = 'date'
                            options = []
                            break
                        case 'last_update_datetime':
                            label = 'Эксплуатация (факт)'
                            type = 'date'
                            options = []
                            break
                        default:
                            break
                    }
                    break
                case 'foundation':
                    disabled = true
                    switch (item.key) {
                        case 'planned_completion_date':
                            label = 'Фундамент (план)'
                            type = 'date'
                            options = []
                            break
                        case 'last_update_datetime':
                            label = 'Фундамент (факт)'
                            type = 'date'
                            options = []
                            break
                        default:
                            break
                    }
                    break
                case 'complaint':
                    switch (item.key) {
                        case 'transfer_reason':
                            textArea = true
                            break
                        default:
                            type = 'date'
                            break
                    }
                    break
                default:
                    break
            }

            const inputProps = {
                subInput: label,
                type: type,
                options: options,
                keyData: item?.key,
                textArea: textArea,
                onChange: (e) => handleChangeInput(item?.key, e, identifier),
                required: item?.value?.required,
                disabled: disabled,
                value: foundValue?.[item.key],
            }

            if (item.key !== 'supplementary_agreements') {
                return (
                    <div className={classContain[key] || `${textArea ? 'col-3' : ''}`} key={index}>
                        <FormInput {...inputProps} />
                    </div>
                );
            }

        });
    };

    // Функция для обновления данных участка
    const updateDistrict = () => {
        let hasError = false;
        dispatch(setDataPressItem(['tab', 'control_building_tab']));
        if (hasError) {
            setFormError("Заполните все обязательные поля.");
        } else {
            landPlot.update({ urlParams: id + '/', body: microdistData }).then((resp) => {
                if (resp.success) {
                    setIsComplete('Данные обновлены')
                } else {
                    setFormError('Ошибка сохранения')
                }
                dispatch(setNeedsRerender(!needsRerender.needsRerender));
            });
        }
    };

    return (
        <div className='continerLandplotPage'>
            {formError &&
                <ErrorMessage
                    type={'error'}
                    message={formError}
                    onClose={() => setFormError(false)}
                />
            }
            {
                isComplete &&
                <ErrorMessage
                    message={isComplete}
                    onClose={() => setIsComplete(false)}
                    type={'success'}
                />
            }


            <div className='inputContainerColPayment'>
                {controlData.bonus_doc_date && controlOptionBonusDocDate &&
                    <div className='inputContainerCol'>
                        <FormInput type={controlOptionBonusDocDate.type} value={controlData.bonus_doc_date} disabled={true} subInput={controlOptionBonusDocDate.label} />
                    </div>
                }
                <div className='inputContainerCol'>
                    <p className='inputContainerColTitle col-3'>Возведение фундамента</p>
                    {controlOptionFoundation && renderFormInputs(controlOptionFoundation, 'foundation')}
                    {microdistData?.control_building_tab?.foundation?.supplementary_agreements &&
                        <div className='col-3'>
                            <FormBlock
                                title='Дополнительное соглашение'
                                textAddButton={'Дополнительное соглашение'}
                                dataOptions={fieldToArray(controlOptionFoundation.find(item => item.key === 'supplementary_agreements')?.value.child?.children)}
                                onChange={(e) => handleChangeInput('supplementary_agreements', e, 'foundation')}
                                initData={microdistData?.control_building_tab?.foundation?.supplementary_agreements}
                                searchSelect={() => { }}
                                classDict={classDict}
                            />
                        </div>
                    }
                </div>
                <div className='inputContainerCol'>
                    <p className='inputContainerColTitle col-3'>Сдача в эксплуатацию</p>
                    {controlOptionExploitationt && renderFormInputs(controlOptionExploitationt, 'exploitation')}
                    {microdistData?.control_building_tab?.exploitation?.supplementary_agreements &&
                        <div className='col-3'>
                            <FormBlock
                                title='Дополнительное соглашение'
                                textAddButton={'Дополнительное соглашение'}
                                dataOptions={fieldToArray(controlOptionFoundation.find(item => item.key === 'supplementary_agreements')?.value.child?.children)}
                                onChange={(e) => handleChangeInput('supplementary_agreements', e, 'exploitation')}
                                initData={microdistData?.control_building_tab?.exploitation?.supplementary_agreements}
                                searchSelect={() => { }}
                                classDict={classDict}
                            />
                        </div>}
                </div>
                <div className='inputContainerCol'>
                    <p className='inputContainerColTitle col-3'>Претензия</p>
                    {controlOptionComplaint && renderFormInputs(controlOptionComplaint, 'complaint')}
                </div>
            </div>
            {isRedact &&
                <Buttons text={'Сохранить'} className={'saveButton'} ico={icons.floppyCheckmark} onClick={() => { updateDistrict() }} />
            }
        </div>
    )
}

export default LandploatFromControl