import BaseModelAPI from "../BaseModelAPI";
import axiosClient from "../axiosClient";
import { API_STATUSES_MODEL } from "./const";

class StatusesApiRequest extends BaseModelAPI {
    constructor() {
        super(API_STATUSES_MODEL.url);
    }
    async listSubStatuses(id) {
        return this.makeRequest(axiosClient.get, { id: id, method: API_STATUSES_MODEL.methods.subStatuses.url});
    }
    async listAllStatuses() {
        return this.makeRequest(axiosClient.get, { method: API_STATUSES_MODEL.methods.allStatuses.url});
    }
}

export default StatusesApiRequest;
