import React, { useState, useRef, useEffect } from 'react';
import './styles.scss';
import icons from '../../assets/icons/icons';

const FormSelector = ({ loadingScroll, options, onChange, error, ico, style, value, disabled, onScroll, onSearch, multiple, placeholder, name }) => {
    const [selectedOption, setSelectedOption] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    const [activeOption, setActiveOption] = useState('');

    const [selectedOptions, setSelectedOptions] = useState([]);
    const [filteredOptions, setFilteredOptions] = useState([]);
    const formSelectorRef = useRef(null);
    const formSelectorOptionRef = useRef(null);
    const debounceSearch = useRef(null);

    useEffect(() => {
        if (!selectedOption)
            setSelectedOption(value === null ? '' : value)
    }, [value])

    const handleScroll = () => {
        if (
            formSelectorOptionRef.current.scrollHeight - formSelectorOptionRef.current.scrollTop - 5 <=
            formSelectorOptionRef.current.clientHeight
        ) {
            onScroll();
            setFilteredOptions(options)
        }
    };
    const handleOptionChange = (option) => {
        if (multiple) {
            // Проверяем, была ли уже выбрана эта опция
            const isSelected = selectedOptions.includes(option.value);

            if (isSelected) {
                // Если опция уже выбрана, удаляем её из выбранных
                setSelectedOptions(prev => prev.filter(item => item !== option.value));
                onChange(selectedOptions.filter(item => item !== option.value));
            } else {
                // Если опция не была выбрана ранее, добавляем её в выбранные
                setSelectedOptions(prev => [...prev, option.value]);
                onChange([...selectedOptions, option.value]);
            }
        } else {
            setSelectedOptions([option.value]);
            setSelectedOption(option.display_name);
            onChange(option.value);
            setIsOpen(false);
        }
    };



    const handleInputClick = () => {
        setIsOpen(!isOpen);
        if (!filteredOptions.length) {
            setFilteredOptions(options);
        }
    };

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        setIsOpen(true);
        setSelectedOption(inputValue); // Обновляем введенное значение в поле

        if (debounceSearch.current) {
            clearTimeout(debounceSearch.current);
        }

        debounceSearch.current = setTimeout(() => {
            if (onSearch) {
                onSearch(inputValue);
            }
        }, 500);

        setFilteredOptions(options.filter(option => option.display_name.toLowerCase().includes(inputValue.toLowerCase())));
        setActiveOption(inputValue);
    };

    const handleClickOutside = (event) => {
        if (formSelectorRef.current && !formSelectorRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (onSearch) {
            setFilteredOptions(options)
        }
    }, [options])

    useEffect(() => {
        const foundOption = options.find(item => item.value === selectedOption);
        if (foundOption) {
            setSelectedOption(foundOption.display_name);
        }
    }, [selectedOption, options]);

    return (
        <div className={`FormSelector ${style}`} ref={formSelectorRef}>
            <div className='formContainerSelector'>
                <input
                    className={`formSelector ${error && 'error'} ${ico ? 'paddingIco' : ''}`}
                    value={multiple ? selectedOptions.map((option) => options.find((opt) => opt.value === option)?.display_name || '').join(', ') : selectedOption}
                    onClick={handleInputClick}
                    onChange={handleInputChange} // Обработчик изменения значения в поле
                    disabled={disabled || false}
                    placeholder={placeholder}
                />
                <img src={icons.chevronDown} className={`downImg ${isOpen && 'upImg'}`} alt="Chevron" onClick={handleInputClick} />
            </div>
            {isOpen && (
                <div className={'optionsContainer'} ref={formSelectorOptionRef} onScroll={() => onScroll && handleScroll()}>
                    {filteredOptions.map((option, index) => (
                        <div
                            key={index}
                            value={option.display_name}
                            className={`optionsItem ${selectedOptions.includes(option.value) ? 'focus' : ''}`}
                            onClick={() => handleOptionChange(option)}
                        >
                            <p>{option.display_name}</p>
                            {selectedOptions.includes(option.value) && <img src={icons.check} alt="Icon" />}
                        </div>
                    ))}
                    {loadingScroll && <p>Загрузка...</p>}
                </div>
            )}
        </div>
    );
};

export default FormSelector;
