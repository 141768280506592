import { createSlice } from '@reduxjs/toolkit';

const filterState = localStorage.getItem('filterLanploat')? JSON.parse(localStorage.getItem('filterLanploat')) : {};

export const dataLandploatFilter = createSlice({
    name: 'data_landploatFilter',
    initialState: filterState,
    reducers: {
        setDataLandploatFilterPressItem(state, action) {
            state[action.payload[0]] = action.payload[1];
        },
        cleardataLandploatFilter(state) {
            // Обнуляем все данные в срезе
            return {};
        },
    },
});

export const { setDataLandploatFilterPressItem, cleardataLandploatFilter } = dataLandploatFilter.actions;

export default dataLandploatFilter.reducer;
