import React from 'react'
import FormInput from '../FormInput/FormInput'

const TableInputCell = ({ listItem, defaultValue, onChange }) => {

    const intutProps = {
        subInput: listItem?.value?.label,
        type: listItem?.key === 'date' ? 'date' : listItem?.value?.type,
        // options: item?.value?.choices,
        keyData: listItem?.key,
        onChange: (e) => handleChangeInput(listItem?.key, e),
        required: listItem?.value?.required,
        disabled: false,
        value: defaultValue,
        style: 'TableInput'
    }

    const handleChangeInput = (key, val) => {
        onChange(key, val)
    }

    return (
        <FormInput {...intutProps} />
    )
}

export default TableInputCell