import { createSlice } from '@reduxjs/toolkit';

const filterState = localStorage.getItem('filterLanploat')? JSON.parse(localStorage.getItem('filterLanploat')) : {};

export const dataMicrodistrictFilter = createSlice({
    name: 'data_landploatFilter',
    initialState: filterState,
    reducers: {
        setDataMicrodistrictFilterPressItem(state, action) {
            state[action.payload[0]] = action.payload[1];
        },
        cleardataMicrodistrictFilter(state) {
            // Обнуляем все данные в срезе
            return {};
        },
    },
});

export const { setDataMicrodistrictFilterPressItem, cleardataMicrodistrictFilter } = dataMicrodistrictFilter.actions;

export default dataMicrodistrictFilter.reducer;
