export const API_DIST_MODEL = {
    entity: 'microdistrict',
    url: 'microdistrict/',
    methods: {
        map: {
            url: '/map/'
        },
        map_stages: {
            url: '/map_stages/'
        },
        map_object: {
            url: '/map_objects/'
        },
        import_municipalities: {
            url: 'import_municipalities/'
        },
        import_microdistrict: {
            url: 'import_microdistrict/'
        },
        fix_name_microdistrict: {
            url: 'fix_name_microdistrict/'
        },
    },
}