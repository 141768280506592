import React, { useEffect, useRef } from 'react';
import './styles.scss'


const Modal = ({ content, onClose }) => {

    useEffect(() => {
        // Заблокировать прокрутку при открытии модального окна
        document.body.style.overflow = 'hidden';

        // Вернуть нормальную прокрутку при закрытии модального окна
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    return (
        <div>
            <div className='modalContainerBg' onClick={() => onClose()}></div>
            <div className='modal' >
                {content}


            </div>
        </div>
    )
}

export default Modal