import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import StatusesApiRequest from "../../api/Statuses/Statuses";
import MicroDistrictApiRequest from "../../api/microDistrict/MicroDistrict";
import { useSelector } from "react-redux";
import groupUser from "../../locales/groupUser";

const DefaultMapFilter = ({url = false, setMapData }) => {
    const location = useLocation()
        , { id } = useParams()
        , statusesApi = new StatusesApiRequest()
        , micropdistPlotInstance = new MicroDistrictApiRequest()
        , microdistData = useSelector((state) => state.mocrodist_data)
        , userData = useSelector(state => state.user_data.userData)
        , isAdmin = userData.user?.groups?.some(group => group.name === groupUser.admin)
        , [mapStatuses, setMapStatuses] = useState('') // статусы и сабстатусы, строка параметров
        , [mapTypes, setMapTypes] = useState('') // строка параметров

    useEffect(() => {
        statusesApi.listAllStatuses().then((resp) => { // статусы и сабстатусы для фильтра карты
            if (resp.success) {
                const queryParams = {
                    status: [],
                    sub_status: []
                };

                for (const status of resp.data) {
                    queryParams.status = [...queryParams.status, status.value]
                    for (const sub_status of status['sub-statuses']) {
                        if (!isAdmin && sub_status.value === 13) { }
                        else {
                            queryParams.sub_status = [...queryParams.sub_status, sub_status.value]
                        }
                    }
                }

                const queryParamsString = Object.keys(queryParams).map(key => {
                    if (Array.isArray(queryParams[key])) {
                        const innerKeyString = queryParams[key].join(",");
                        return `${key}=${innerKeyString}`;
                    }
                });

                const urlParams = queryParamsString.join('&');
                setMapStatuses(urlParams)
            }
        })
        micropdistPlotInstance.microdistrictOptions().then((resp) => { // типы для фильтра карты
            if (resp.success) {
                // setMicrodistOption(resp.data.actions.map);

                let map_options = ''
                switch (location?.pathname) {
                    case '/object-map':
                        map_options = resp.data.actions.filter_objects_map
                        break;
                    case '/controlsMap':
                        map_options = resp.data.actions.filter_stages_map
                        break;
                    default:
                        map_options = resp.data.actions.filter_map
                }
                const arr = []
                for (let choice of map_options.type?.choices) {
                    arr.push(choice.value)
                }
                setMapTypes('&type=' + arr.join(','))
            }
        })
    }, []);

    useEffect(() => {
        let u = id ? microdistData.inform_tab?.micro_district : microdistData.micro_district
        if (url) {
            u = url
        }
        if (mapStatuses && mapTypes && u !== undefined) {
            micropdistPlotInstance.mapApiFilter(u, '?' + mapStatuses + mapTypes)
                .then(resp => {
                    if (resp.success) {
                        setMapData(resp.data)
                    }
                })
                .catch((error) => {
                    console.error('Ошибка при получении данных:', error);
                });
        }
    }, [mapStatuses, mapTypes])

    return <></>
}

export default DefaultMapFilter