import React from "react";
import './styles.scss'

const ContentLoader = () => {
    return (
        <>
            <div className="box">
                <div className="cat-box">
                    <div className="cat">
                        <div className="helf-box helf-box--ass">
                            <div className="helf-box clip">
                                <div className="cat__body"></div>
                            </div>
                            <div className="cat__ass">
                                <div className="cat__body--fake"></div>
                                <div className="cat__foots"></div>
                                <div className="cat__tail"></div>
                            </div>
                        </div>
                        <div className="helf-box helf-box--head">
                            <div className="cat__head">
                                <div className="cat__face">
                                    <div className="cat__ears"></div>
                                    <div className="cat__eyes"></div>
                                    <div className="cat__mouth"></div>
                                    <div className="cat__mustache"></div>
                                </div>
                                <div className="cat__foots"></div>
                            </div>
                            <div className="helf-box clip">
                                <div className="cat__body"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>

    )
}
export default ContentLoader;