import BaseModelAPI from "../BaseModelAPI";
import apiConfig from "../apiConfig";
import axiosClient from "../axiosClient";
import { API_COMMENT_MODEL } from "./const";

class CommmentApiRequest extends BaseModelAPI {
    constructor() {
        super(API_COMMENT_MODEL.url, apiConfig.baseUrlUser);
    }

 
    async oldUploadImage(data) {
        return this.makeRequest(axiosClient.post, { method: API_COMMENT_MODEL.methods.oldUploadImage.url, body: data });
    }
}

export default CommmentApiRequest;
