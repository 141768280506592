import { createSlice } from '@reduxjs/toolkit';

const dataMicrodistState = {};

export const dataMicrodist = createSlice({
    name: 'data_microdist',
    initialState: dataMicrodistState,
    reducers: {
        setDataPressItem(state, action) {
            state[action.payload[0]] = action.payload[1];
        },
        clearDataMicrodistKey(state, action) {
            delete state[action.payload];
        },
        clearDataArrayMicrodistKey(state, action) {
            // Фильтруем массив fields, оставляя только элементы, индекс которых не равен action.payload
            state.fields = state.fields.filter((_, index) => index !== action.payload);
        },
        clearDataNullMicrodistKey(state, action) {
            // Очищаем значение по ключу
            state[action.payload[0]] = null;
        },
        clearDataMicrodist(state) {
            // Обнуляем все данные в срезе
            return {};
        },
    },
});

export const { setDataPressItem, clearDataMicrodist, clearDataArrayMicrodistKey, clearDataMicrodistKey, clearDataNullMicrodistKey } = dataMicrodist.actions;

export default dataMicrodist.reducer;
