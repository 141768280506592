import React, { useMemo, useState } from 'react';
import './styles.scss';
import icons from '../../assets/icons/icons';
import Tables from '../Tables/Tables';
import { useSelector } from 'react-redux';

const RightSidebar = ({ openRightBar, onClosed, isList, barData }) => {
  const [emptyPayment, setEmptyPayment] = useState(false)
  const microdistData = useSelector((state) => state.mocrodist_data)
    , isData = useMemo(() => {
      if (barData?.matchingKey && barData?.key) {
        const tableData = microdistData.payment_tab[barData.matchingKey]?.[barData.key]
        if (tableData)
          return tableData
      }
      return []
    }, [{ ...microdistData }, { ...barData }])

  const addEmptyPayment = () => {
    setEmptyPayment(true)
  }

  const closeCreate = () => {
    setEmptyPayment(false)
  }

  return (
    <div className={`rightSidbarContainer ${openRightBar && 'activeBar'}`}>
      <div className='sidebarHeader' >
        <div className='sidebarClosedButton' onClick={() => { onClosed() }} >
          <img src={icons.ArrowBack}></img>
          <p>Закрыть</p>
        </div>
        <button className='sidebarAddPaymentButton' onClick={addEmptyPayment}>Добавить платеж</button>
      </div>
      <p className='sidebarTitle'>{barData?.title}</p>
      <Tables isList={isList} isData={isData} options={barData?.options} paymentData={barData?.paymentData} emptyPayment={emptyPayment} type={'payment_history'} closeCreate={closeCreate} />
    </div>
  )
}

export default RightSidebar