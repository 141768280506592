import React from 'react';
import Buttons from '../../component/Buttons/Buttons';
import './styles.scss';
import icons from '../../assets/icons/icons';
import { useHistory } from 'react-router-dom';


const ErrorPage = () => {

    const history = useHistory();

    const goBack = () => {
        history.goBack();
    };


    return (
        <div className='ErrorPage'>
            <div className='errPage'>
                <p className='errSubtitle'>Ошибка 404</p>
                <h1 className='errTitle'>Страница не найдена</h1>
                <h3 className='errText'>Похоже, страница, которую вы ищите перемещена или еще не создана</h3>
            </div>
            <div className='errContainerBottom'>
                <Buttons ico={icons.arrowLeft} text={'Вернуться назад'} className={'buttonWhite'} onClick={goBack}></Buttons>
                <Buttons text={'Перейти на главную'} className={"buttonBlue"} onClick={() => { history.push('/') }}></Buttons>
            </div>
        </div>
    )
}

export default ErrorPage;