import React, { useRef, useState } from 'react';
import apiConfig from '../../../api/apiConfig';
import './styles.scss';
import icons from '../../../assets/icons/icons';
import Modal from '../../Modal/Modal';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ImageThumbnail = ({ imageData, upload, onClose, className, modal }) => {
    const [downloading, setDownloading] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);
    
    const sliderRef = useRef(null);
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: false,
    };

    // Функция для скачивания всех изображений
    const downloadAllImages = async () => {
        setDownloading(true);
        const promises = imageData.map(async (image, index) => {
            const response = await fetch(apiConfig.baseUrlMedia + image.url);
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `image_${index}`;
            link.click();
        });
        await Promise.all(promises);
        setDownloading(false);
    };
    
    return (
        <>
            {selectedImageIndex !== null && modal && (
                <Modal
                    content={
                        <Slider {...settings} ref={sliderRef} initialSlide={selectedImageIndex}>
                            {imageData?.map((slide, index) => (
                                <div key={index}>
                                    <img src={apiConfig.baseUrlMedia + slide.url} className='imgItem' />
                                </div>
                            ))}
                        </Slider>
                    }
                    onClose={() => setSelectedImageIndex(null)}
                />
            )}

            <div className={`containerImg ${upload && 'bigContainer'} ${className}`}>
                {(imageData?.length !== 0 && imageData != undefined) &&
                    imageData.map((img, index) => (
                        <div key={index} className={`imgContainerItem ${upload && 'bigContainerItem'}`} onClick={() => { setSelectedImageIndex(index) }}>
                            <img src={!upload ? apiConfig.baseUrlMedia + (img.url) : apiConfig.baseUrlMedia + img.url} alt={`Image ${index}`} className='imgItem' style={modal && { cursor: 'zoom-in' }} />
                            {!upload &&
                                <div className='buttonClose' onClick={() => { onClose() }}>
                                    <img src={icons.xClose} alt={`Image ${index}`} />
                                </div>
                            }
                        </div>
                    ))
                }
                  {upload &&
                    <div className='buttonUpload' onClick={downloadAllImages}>
                        <img src={icons.Upload} className='iconUploadButton' style={modal && { cursor: 'zoom-in' }}></img>
                        <p className='textUploadButton'>{downloading ? 'Загрузка...' : imageData.length > 1 ? 'Скачать все' : 'Скачать'}</p>
                    </div>
                }
            </div>
        </>
    );
};

export default ImageThumbnail;
