import BaseModelAPI from "../BaseModelAPI";
import axiosClient from "../axiosClient";
import { API_LANDPLOT_MODEL } from "./const";

class LandPlotApiRequest extends BaseModelAPI {
    constructor() {
        super(API_LANDPLOT_MODEL.url);
    }

    async uploadImage(data) {
        return this.makeRequest(axiosClient.post, { method: API_LANDPLOT_MODEL.methods.uploadImage.url, body: data });
    }
    async stagesSetComplete(id, data) {
        return this.makeRequest(axiosClient.post, { id: id, method: API_LANDPLOT_MODEL.methods.stagesSetComplete.url, body:data });
    }
    async createInspection(id, data) {
        return this.makeRequest(axiosClient.post, { id: id, method: API_LANDPLOT_MODEL.methods.createInspection.url, body: data });
    }
    async constructionControlList() {
        return this.makeRequest(axiosClient.options, {method: API_LANDPLOT_MODEL.methods.constructionControlList.url});
    }
    async getConstructionControlList({urlParams}) {
        return this.makeRequest(axiosClient.get, {method: API_LANDPLOT_MODEL.methods.constructionControlList.url, urlParams: urlParams});
    }
    async getByIdConstructionControlList(id) {
        return this.makeRequest(axiosClient.get, {id:`${id}/`,method: API_LANDPLOT_MODEL.methods.constructionControlList.url});
    }
    async stageRevert(id) {
        return this.makeRequest(axiosClient.post, { id: id, method: API_LANDPLOT_MODEL.methods.stageRevert.url});
    }
    async dischargeLandploats(data) {
        return this.makeRequest(axiosClient.post, { method: API_LANDPLOT_MODEL.methods.discharge.url, body: data });
    }
    async deleteContragentContract(id) {
        return this.makeRequest(axiosClient.post, {id: `${id}/`, method: API_LANDPLOT_MODEL.methods.deleteContragentContract.url });
    }
}

export default LandPlotApiRequest;
