import React, { Fragment, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import Header from '../../component/Header/Header';
import MapComponents from '../../component/MapComponents/MapComponents';
import icons from '../../assets/icons/icons';
import LandPlot from '../../api/Landplot/LandPlot';
import { Link, useLocation } from 'react-router-dom';
import Buttons from '../../component/Buttons/Buttons';
import MicroDistrictApiRequest from '../../api/microDistrict/MicroDistrict';
import FormInput from '../../component/FormInput/FormInput';
import './styles.scss';
import MapFilter from '../../component/MapFilter/MapFilter';
import { useSelector } from 'react-redux';
import Loading from '../../component/Loader/Loader';
import groupUser from '../../locales/groupUser';
import StatusesApiRequest from '../../api/Statuses/Statuses';

const MapPage = () => {
    const micropdistPlotInstance = new MicroDistrictApiRequest();
    const statusesApi = new StatusesApiRequest();

    const [coordinatesItem, setCoordinatesItem] = useState([]);
    const [microdistOption, setMicrodistOption] = useState();
    const [isStatuses, setStatuses] = useState();
    const [optionsMapFilter, setOptionsMapFilter] = useState();
    const initialDistrict = Number(localStorage.getItem('activeDistrict')) || (microdistOption?.id?.choices[0]?.value);
    const [isActiveDistrict, setActiveDistrict] = useState(initialDistrict);
    const [cadastreLayerVisible, setCadastreLayerVisible] = useState(false);

    const [districtData, setDistrictData] = useState();
    const [isLoading, setLoading] = useState(true);
    const [isUpdate, setUpdate] = useState(true);
    const userData = useSelector(state => state.user_data.userData);

    const location = useLocation();

    useEffect(() => {
        setLoading(true);
        try {
            statusesApi.listAllStatuses().then((resp) => {
                if (resp.success) {
                    setStatuses(resp.data)

                    micropdistPlotInstance.microdistrictOptions().then((resp) => {
                        if (resp.success) {
                            setMicrodistOption(resp.data.actions.map);
                            switch (location?.pathname) {
                                case '/object-map':
                                    setOptionsMapFilter(resp.data.actions.filter_objects_map);
                                    break;
                                case '/controlsMap':
                                    setOptionsMapFilter(resp.data.actions.filter_stages_map);
                                    break;
                                default:
                                    setOptionsMapFilter(resp.data.actions.filter_map);
                            }
                            setActiveDistrict(localStorage.getItem('activeDistrict') ? Number(localStorage.getItem('activeDistrict')) : resp.data.actions.map?.id?.choices[0].value);
                            setLoading(false);
                        }

                    });
                }
            })


        } catch (error) {
            console.error('Error fetching microdistrict options:', error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    }, [location]);

    useEffect(() => {
        setLoading(true);
        if (isActiveDistrict) {
            try {
                micropdistPlotInstance.mapApi(isActiveDistrict, '?ignore_filters').then((resp) => {
                    if (resp.success) {
                        const reversedCoordinates = resp.data.coordinates.map((item) => item);
                        setDistrictData(resp.data);
                        setCoordinatesItem(reversedCoordinates);
                        setLoading(false)
                    } else {
                        setLoading(false)
                    }
                });
            } catch (error) {
                console.error('Error fetching map data:', error);
                setLoading(false);
            }

        }
    }, [isActiveDistrict]);


    const onChangeDataMap = (data) => {
        const reversedCoordinates = data.coordinates.map((item) => item);
        setDistrictData(data);
        setCoordinatesItem(reversedCoordinates);
    }

    const storedState = useSelector(state => state.updateSidebar.updateSidebar);

    const handleActiveDistrict = (e) => {
        setActiveDistrict(e)
        localStorage.setItem('activeDistrict', e)
    }


    const toggleCadastreLayer = () => {
        setCadastreLayerVisible(!cadastreLayerVisible);
    };




    return (
        <>
            {
                isLoading ?
                    <></>
                    :
                    <Header
                        name={'Карта'}
                        icon={icons.map1}

                        buttonContent={
                            <div className='mapRegionContainer'>
                                <Buttons
                                    className={'toggleCadastreLayerButton'}
                                    text={cadastreLayerVisible ? 'Скрыть кадастровый слой' : 'Показать кадастровый слой'}
                                    onClick={toggleCadastreLayer}
                                />
                                <FormInput
                                    value={isActiveDistrict}
                                    options={microdistOption?.id?.choices}
                                    onChange={(e) => { handleActiveDistrict(e) }}
                                />

                            </div>
                        }
                    />
            }
            {isLoading ? <Loading /> : (
                <div className={`mapPageContainer ${storedState && "active"}`}>

                    {districtData?.land_plots || districtData?.geo_objects ?
                        <>
                            <MapComponents coordinats={coordinatesItem} landplot={districtData?.land_plots} geoObject={districtData?.geo_objects} activeDistrict={isActiveDistrict} isClick />
                            <MapFilter districtData={districtData} activeDistrict={isActiveDistrict} optionMicrodist={microdistOption?.id?.choices} dataStatus={isStatuses} optionsMap={optionsMapFilter} onChangeDataMap={(e) => { onChangeDataMap(e) }} />
                        </>
                        :
                        <></>
                    }
                </div>
            )}
        </>
    );
};

export default MapPage;
