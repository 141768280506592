// Функция обновления данных по ключу
export const updateDataKey = (data, keyPath, value) => {
    if (keyPath.length === 1) {
        // Если ключевой путь состоит из одного элемента, устанавливаем значение
        data[keyPath[0]] = value;
    } else if (keyPath.length > 1) {
        const nextKey = keyPath.shift();
        if (data[nextKey] == undefined) {
            // Если ключ не существует, создаем его как пустой массив или объект
            data[nextKey] = Array.isArray(data[nextKey]) ? [] : {};
        }
        // Рекурсивно вызываем функцию для следующего ключа в пути
        updateDataKey(data[nextKey], keyPath, value);
    }
    return data;
};
