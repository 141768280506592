export default {
    controlBuild: 'СapitalСonstruction',
    PaymentDeportament: 'Payment_departament',
    cadastralGroup: 'Cadastral_group',
    Exploitation: 'Exploitation',
    ngeo_client_view_only: 'ngeo_client_view_only',
    auditor: 'auditor',
    admin: 'Admin',
    controlBuildTabSaveBtn: 'controlBuildTabSaveBtn',
    pirsTabSaveBtn: 'pirsTabSaveBtn'
}

//Не удалять (если будете удалять просьба пересмотреть во всех компонентах пропсы isRedact и isDontRedact так как там используется groupUser) это для старой версии