export const API_LANDPLOT_MODEL = {
    entity: 'reports',
    url: 'reports/',
    methods: {
        map: {
            url: 'map/'
        },
        uploadImage: {
            url: 'upload/'
        },
        stagesSetComplete: {
            url: '/stage_set_complete/'
        },
        constructionControlList: {
            url: 'construction-control-list/'
        },
        getConstructionControlList: {
            url: 'construction-control-list/'
        },
        createInspection: {
            url: '/create_inspection/'
        },
        discharge: {
            url: 'discharge/'
        },
        stageRevert: {
            url: 'stage_set_revert/'
        },
        deleteContragentContract: {
            url: 'delete-contragent-from-contract/'
        },
        regenerateFile: {
            url: 'generate-file/'
        }
    },
}