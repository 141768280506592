import { createSlice } from '@reduxjs/toolkit';

const filterState = localStorage.getItem('filterClients')? JSON.parse(localStorage.getItem('filterClients')) : {};

export const dataClientsFilter = createSlice({
    name: 'data_clientsFilter',
    initialState: filterState,
    reducers: {
        setDataClientsFilterPressItem(state, action) {
            state[action.payload[0]] = action.payload[1];
        },
        cleardataClientsFilter(state) {
            // Обнуляем все данные в срезе
            return {};
        },
    },
});

export const { setDataClientsFilterPressItem, cleardataClientsFilter } = dataClientsFilter.actions;

export default dataClientsFilter.reducer;
