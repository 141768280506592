import React, { useEffect, useState } from 'react';
import './styles.scss';
import FormInput from '../FormInput/FormInput';
import FormBlock from '../FormBlock/FormBlock';
import Buttons from '../Buttons/Buttons';
import icons from '../../assets/icons/icons';
import ModalMap from '../ModalMap/ModalMap';
import { useDispatch, useSelector } from 'react-redux';
import { clearDataMicrodist, setDataPressItem } from '../../store/microdistDataReduces';
import MicroDistrict from '../../api/microDistrict/MicroDistrict';
import { useHistory, useParams } from 'react-router-dom';
import { fieldToArray, transformDate } from '../UI/functions/functions';
import Checkbox from '../Checkbox/Checkbox';
import ErrorMessage from '../UI/ErrorMassage/ErrorMassage';
import FileAttached from '../UI/FileAttached/FileAttached';
import MicroDistrictApiRequest from '../../api/microDistrict/MicroDistrict';
import MapComponents from '../MapComponents/MapComponents';
import groupUser from '../../locales/groupUser';
import DefaultMapFilter from '../DefaultMapFilter/DefaultMapFilter';

const MicrodistrictContain = ({ data, isRedact, isDontRedact }) => {

    const userData = useSelector(state => state.user_data.userData);
    const isAdmin = userData?.user?.groups?.some(group => group.name === groupUser.admin);

    const microDistrict = new MicroDistrict();
    const dispatch = useDispatch();
    const [dataOption, setOptions] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [isRegion, setIsRegion] = useState('');
    const [isErr, setIsErr] = useState(false);
    const microdistData = useSelector((state) => state.mocrodist_data);

    const micropdistPlotInstance = new MicroDistrictApiRequest();
    const [isCoordinatsMicroDistrict, setIsCoordinatsMicroDistrict] = useState([]);
    const [districtData, setDistrictData] = useState();

    const history = useHistory();
    const { id } = useParams();

    useEffect(() => {
        const dataField = fieldToArray(data);
        dataField?.forEach((item) => {
            if (item.key === 'system_status') {
                item?.value?.map((choise) => {
                    handleSystemStatusChange(choise)
                })
            } else {
                dispatch(setDataPressItem([item.key, item.value]));
            }

        });


    }, [data, dispatch]);

    // useEffect(() => {
    //     if (id) {
    //         micropdistPlotInstance.mapApi(data.id, '?ignore_filters')
    //             .then((resp) => {
    //                 if (resp.success) {
    //                     setIsCoordinatsMicroDistrict(resp.data.coordinates);
    //                     setDistrictData(resp.data.land_plots)
    //                 }
    //             })
    //             .catch((error) => {
    //                 console.error('Ошибка при получении данных:', error);
    //             });
    //     }
    // }, [])

    const setMapData = (data) => {
        setDistrictData(data.land_plots)
        setIsCoordinatsMicroDistrict(data.coordinates);
    }

    useEffect(() => {
        microDistrict.options().then((resp) => {
            const options = fieldToArray(resp.data.actions.create);
            setOptions(options);
        });

    }, []);

    useEffect(() => {
        const region = dataOption
            .filter((item) => item.key === 'municipality')
            .map((item) =>
                item.value.choices.find((data) =>
                    microdistData.municipality === data.value ? data.display_name : ''
                )
            );
        setIsRegion(region);
    }, [microdistData, dataOption]);


    useEffect(() => {
        if (id === undefined) {
            dataOption.forEach((item) => {
                if (item.key === 'system_status') {
                    item.value.choices.map((dataChoice) => {
                        handleSystemStatusChange(dataChoice.value)
                    })
                }
            })
        }
    }, [dataOption])

    const [selectedSystemStatus, setSelectedSystemStatus] = useState([]);

    const handleChangeInput = (field, value) => {
        dispatch(setDataPressItem([field, value]));
    };

    const handleToggleField = (fieldName, isChecked) => {
        dispatch(setDataPressItem([fieldName, isChecked]));
    };

    const handleSystemStatusChange = (itemValue) => {
        if (selectedSystemStatus.includes(itemValue)) {
            setSelectedSystemStatus((prevSelected) =>
                prevSelected.filter((value) => value !== itemValue)
            );
        } else {
            setSelectedSystemStatus((prevSelected) => [...prevSelected, itemValue]);
        }
    };

    useEffect(() => {
        handleChangeInput('system_status', selectedSystemStatus)
    }, [selectedSystemStatus])

    // const saveOrUpdateDistrict = (isUpdate) => {
    //     const action = isUpdate ? microDistrict.update : microDistrict.create;
    //     const urlParams = isUpdate ? data.id + '/' : '';

    //     action({ body: microdistData }).then((resp) => {
    //         if (resp.success) {
    //             dispatch(clearDataMicrodist());
    //             history.push('/');
    //         }
    //     });
    // };

    const saveDisctrict = () => {
        let hasError = false;

        dataOption.forEach((item) => {
            if (item.value.required && (!microdistData[item.key] || microdistData[item.key].length === 0)) {
                hasError = true;
            }
        });

        if (hasError) {
            setIsErr("Заполните все обязательные поля.");
        } else {
            microDistrict.create({ body: microdistData }).then((resp) => {
                if (resp.success) {
                    dispatch(clearDataMicrodist());
                    history.push('/');
                } else {
                    setIsErr(resp.message);
                }
            }).catch((item) => {
                setIsErr(item.message);
            });
        }
    }

    const updateDisctrict = () => {
        let hasError = false;

        dataOption.forEach((item) => {
            if (item.value.required && (!microdistData[item.key] || microdistData[item.key].length === 0)) {
                hasError = true;
            }
        });

        if (hasError) {
            setIsErr("Заполните все обязательные поля.");
        } else {
            microDistrict.update({ urlParams: data.id + '/', body: microdistData }).then((resp) => {
                if (resp.success) {
                    dispatch(clearDataMicrodist());
                    history.push('/')
                } else {
                    setIsErr(resp.message)
                }
            }).catch((item) => {
                setIsErr(item.message)
            })
        }
    }

    const deleteDistrict = () => {
        microDistrict.delete({ urlParams: data.id + '/' }).then((resp) => {
            if (resp.success) {
                dispatch(clearDataMicrodist());
                history.push('/');
            }
        });
    };

    useEffect(() => {
        if (id === undefined) {
            dispatch(clearDataMicrodist());
        }
    }, [id]);


    const dataFields = {
        gas: ['gas_supply_building_status', 'gas_supply_date_end'],
        water: ['water_supply_building_status', 'water_supply_date_end', 'sanitation', 'property'],
        disposal: ['water_disposal_building_status', 'water_disposal_date_end', 'water_disposal_property'],
        road: ['road_surface_building_status', 'road_surface_date_end', 'road_surface_type'],
        power: ['power_supply_building_status', 'power_supply_date_end'],
        commercial: ['commercial_object'],
        social: ['social_object'],
    };

    const renderedKeys = [
        ...dataFields.gas,
        ...dataFields.water,
        ...dataFields.disposal,
        ...dataFields.road,
        ...dataFields.power,
        ...dataFields.commercial,
        ...dataFields.social,
    ];
    const classMappings = {
        'gas_supply_building_status': 'col-1',
        'power_supply_building_status': 'col-1',
        'water_supply_building_status': 'col-1',
        'road_surface_building_status': 'col-1',
        'gas_supply_date_end': 'col-2',
        'power_supply_date_end': 'col-2',
        'water_supply_date_end': 'col-2',
        'road_surface_date_end': 'col-2',
        'commercial_object': 'col-3',
        'social_object': 'col-3',
    };

    const classContin = {
        'date_start': 'col-1',
        'cadastral': 'col-1',
        'type': 'col-1',
        'date_end': 'col-2',
        'number': 'col-2',
        'area': 'col-2',
    };

    return (
        <div className="continerPage">
            <DefaultMapFilter url={data.id} setMapData={setMapData} />
            {
                isErr &&
                <ErrorMessage
                    message={isErr}
                    onClose={() => setIsErr(false)}
                    type={'error'}
                    onClick={() => {
                        id == undefined ? saveDisctrict() : updateDisctrict();
                    }}
                />
            }
            {openModal && (
                <>
                    <ModalMap
                        onClose={() => setOpenModal(false)}
                        titleHeader={microdistData.name}
                        subTitleHeader={microdistData.municipality}
                        regionData={id && microdistData.id}
                        isRedact={isRedact}
                        isCreateMicrodist={true}
                    />
                </>
            )}
            <div className="inputContainerCol">
                {dataOption.map((item, index) => {
                    const key = item.key;
                    const value = item.value;
                    const isSpecialField = [
                        'is_gas_supply',
                        'is_water_supply',
                        'is_water_disposal',
                        'is_road_surface',
                        'is_power_supply',
                        'is_social_objects',
                        'is_commercial_objects',
                        'emergency_service',
                    ].includes(key);
                    const emergencyDataResults =
                        key === 'emergency_service' && fieldToArray(value.child.children);

                    if (key === 'id' || key === 'coordinates' || renderedKeys.includes(key)) {
                        return null;
                    }
                    renderedKeys.push(key);

                    return (
                        <div className={classContin[key] || 'col-3'} key={index}>
                            {isSpecialField ? (
                                key !== 'emergency_service' ? (
                                    <div className={classContin[key] || 'fromBlockGray col-3'} key={index}>
                                        <FormInput
                                            subInput={value.label}
                                            type={value.type}
                                            options={value.choices}
                                            onChange={handleToggleField}
                                            classesTextToggle={'textToggle'}
                                            keyData={key}
                                            required={value.required}
                                            value={microdistData[key]}
                                            checked={microdistData[key]}
                                            error={isErr}
                                            disabled={isDontRedact}
                                        />

                                        <div className={`contentBlockGray ${microdistData[key] ? '' : 'visibleBlock'}`} key={key}>
                                            {dataOption
                                                .filter((itemData) => {
                                                    const fieldData = dataFields[key.split('_')[1]];

                                                    return fieldData && fieldData.includes(itemData.key);
                                                })
                                                .map((itemData, subIndex) => {
                                                    const commercialDataResults =
                                                        dataOption
                                                            .filter((itemData) => itemData.key == 'commercial_object')
                                                            .reduce((acc, itemData) => {
                                                                const commercialFields = fieldToArray(itemData.value.child.children);
                                                                return [...acc, ...commercialFields];
                                                            }, []);

                                                    const socialDataResults =
                                                        dataOption
                                                            .filter((itemData) => itemData.key == 'social_object')
                                                            .reduce((acc, itemData) => {
                                                                const socialFields = fieldToArray(itemData.value.child.children);
                                                                return [...acc, ...socialFields];
                                                            }, []);

                                                    return (
                                                        <div className={classMappings[itemData.key] || 'col-3'} key={subIndex}>
                                                            {itemData.key !== 'commercial_object' && itemData.key !== 'social_object' ? (
                                                                <FormInput
                                                                    subInput={itemData.value.label}
                                                                    type={itemData.value.type}
                                                                    options={itemData.value.choices}
                                                                    keyData={itemData.key}
                                                                    onChange={(e) => handleChangeInput(itemData.key, e)}
                                                                    required={itemData.value.required}
                                                                    value={microdistData[itemData.key]}
                                                                    error={isErr}
                                                                    disabled={isDontRedact}
                                                                />
                                                            ) : itemData.key === 'commercial_object' ? (
                                                                <FormBlock
                                                                    title={`Коммерческий объект`}
                                                                    dataOptions={commercialDataResults}
                                                                    onChange={(e) => handleChangeInput('commercial_object', e)}
                                                                    initData={microdistData?.commercial_object}
                                                                    textAddButton={'Добавить объект'}
                                                                />
                                                            ) : (
                                                                <FormBlock
                                                                    title={`Социальный объект`}
                                                                    dataOptions={socialDataResults}
                                                                    onChange={(e) => handleChangeInput('social_object', e)}
                                                                    initData={microdistData?.social_object}
                                                                    textAddButton={'Добавить объект'}
                                                                />
                                                            )}
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                    </div>
                                )
                                    :
                                    (
                                        <div className="col-3">
                                            <h1 className='titleBlock'>Аварийные службы</h1>
                                            <FormBlock
                                                title={`Служба`}
                                                dataOptions={emergencyDataResults}
                                                onChange={(e) => handleChangeInput('emergency_service', e)}
                                                initData={microdistData.emergency_service}
                                                textAddButton={'Добавить службу'}
                                            />
                                        </div>
                                    )
                            ) : (
                                <div className={classContin[key] || 'col-3'}>
                                    {key === "system_status" ?
                                        <>
                                            <h1 className='microTitle'>Статус в системе</h1>
                                            {value.choices.map((item) => {
                                                const isChecked = microdistData?.system_status?.includes(item.value);
                                                return (
                                                    <Checkbox
                                                        key={item.value}
                                                        id={item.value}
                                                        label={item.display_name}
                                                        checked={isChecked}
                                                        onChange={() => handleSystemStatusChange(item.value)}
                                                    />
                                                )
                                            })}

                                        </>
                                        :
                                        <>

                                            {key === 'files' ?
                                                (
                                                    <div className='col-3' key={index}>
                                                        <div className='fromBlockGray'>
                                                            <h1>{item?.value?.label}</h1>
                                                            <FileAttached
                                                                filesData={microdistData[key]}
                                                                setFiles={(e) => { handleChangeInput(key, e) }}
                                                                addFile={true}
                                                            />

                                                        </div>
                                                    </div>
                                                )
                                                :
                                                <FormInput
                                                    subInput={value.label}
                                                    type={value.type}
                                                    options={value.choices}
                                                    keyData={key}
                                                    onChange={(e) => handleChangeInput(key, e)}
                                                    required={value.required}
                                                    key={index}
                                                    value={microdistData[key]}
                                                    error={isErr}
                                                    disabled={isDontRedact}
                                                />
                                            }
                                            {key === 'name' && (
                                                <Buttons text={'Указать границы'} onClick={() => setOpenModal(true)} />
                                            )}
                                        </>
                                    }

                                </div>
                            )}
                        </div>
                    );
                })}
                {id != undefined && isAdmin && (
                    <Buttons
                        text={'Удалить микрорайон'}
                        className={'deleteButton col-3'}
                        onClick={() => {
                            deleteDistrict();
                        }}
                    />
                )}
            </div>
            {id &&
                <div className='mapContainer'>
                    {districtData ? (
                        <MapComponents
                            coordinats={
                                data ? data?.coordinates : []
                            }
                            styleContainer={"styleMapContainer"}
                            mapStyle={"mapStyle"}
                            modal={false}
                            onClose={() => { }}
                            onChange={() => { }}
                            landplot={districtData}
                            optionsMap={undefined}
                        />
                    ) : (
                        id ? <p>Загрузка карты</p> : <></>
                    )}
                </div>
            }

            {
                isRedact &&
                <Buttons
                    text={'Сохранить'}
                    className={'saveButton'}
                    ico={icons.floppyCheckmark}
                    onClick={() => {
                        id == undefined ? saveDisctrict() : updateDisctrict();
                    }}
                />
            }
        </div>
    );
};

export default MicrodistrictContain;
