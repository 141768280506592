import React from 'react';
import './style.scss';
import { useSelector } from 'react-redux';



const Header = ({ name, icon, pageType, buttonContent, lenght }) => {
   
    const storedState = useSelector(state => state.updateSidebar.updateSidebar);

    return (
        <header className={`header ${storedState ? 'active' : ''}`}>
            <div className='header__title'>
                <img src={icon} />
                <div className='headerContentText'>
                    <p>{name}</p>
                    {lenght && <p className='number'>{lenght}</p>}
                </div>
            </div>
            {buttonContent}
        </header>
    );
};

export default Header;
