import React from 'react';
import './styles.scss';
import Buttons from '../Buttons/Buttons';
import icons from '../../assets/icons/icons';
import { fieldToArray } from '../UI/functions/functions';
import TableRow from './TableRow';

const Tables = ({ isData, isList, next, options, type, onLoad, isTotals, isLoadingButton, paymentData, emptyPayment = false, closeCreate = null}) => {

    return (
        <>
            <div className='containerPage'>
                <table border="0" className='tabsList'>
                    <thead>
                        <tr>
                            {isList !== undefined && isList?.map((item) => {
                                if (item.key !== 'id' && item.key !== "landplot_id" && item.key !== 'report_status') {
                                    return (
                                        <th key={item.key}>{item.value.label}</th>
                                    )
                                }
                                return null;
                            })}
                        </tr>
                        <tr >
                            {['', '', ...fieldToArray(isTotals)].map((item, index) => (
                                <th key={index} style={{ textAlign: 'right', padding: 0, paddingRight: 12 }}>
                                    {item === 0 ? 0 : item.value}
                                </th>
                            ))}
                        </tr>
                    </thead>

                    <tbody>
                        {isData?.map((item, index) => <TableRow key={index} isList={isList} item={item} paymentData={paymentData} options={options} type={type} />)}
                        {emptyPayment && <TableRow isList={isList} item={{}} paymentData={paymentData} options={options} type={type} isCreate={true} closeCreate={closeCreate} />}
                    </tbody>

                </table>

            </div>
            {(isLoadingButton) && (
                <Buttons
                    ico={!isLoadingButton ? icons.dotsAnimate : icons.dotsMenu}
                    text={'Загрузить ещё'}
                    className={`addPageTabs ${!isLoadingButton && 'loadingButton'}`}
                    onClick={onLoad}

                />
            )}
        </>
    )
}

export default Tables;
