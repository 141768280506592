import React, { useEffect, useMemo, useState } from 'react';
import './styles.scss';
import FormBlock from '../FormBlock/FormBlock';
import { useDispatch, useSelector } from 'react-redux';
import { updateDataKey } from '../UI/functions/updateDataKey/updateDataKey';
import { clearDataMicrodist, clearDataMicrodistKey, setDataPressItem } from '../../store/microdistDataReduces';
import icons from '../../assets/icons/icons';
import Buttons from '../Buttons/Buttons';
import { useParams } from 'react-router-dom';
import LandPlotApiRequest from '../../api/Landplot/LandPlot';
import { setNeedsRerender } from '../../store/needsRerenderSlice';
import UserApiRequest from '../../api/User/User';
import ErrorMessage from '../UI/ErrorMassage/ErrorMassage';



const LandploatFormPris = ({ optionsАgreements, optionsClaim, optionsCourtCases, isRedact }) => {
    const microdistData = useSelector((state) => state.mocrodist_data);
    const dispatch = useDispatch();
    const { id } = useParams();
    const landPlot = new LandPlotApiRequest();
    const needsRerender = useSelector((state) => state.needsRerender);

    const userApi = new UserApiRequest()
        , [userData, setUserData] = useState([])
        , [loading, setLoading] = useState(false)
        , [isNextUsers, setIsNextUsers] = useState('')
        , [formError, setFormError] = useState('')
        , [isComplete, setIsComplete] = useState('')




    const onSearchUser = (e) => {
        userApi.search(e).then((resp) => {
            if (resp.success) {
                setUserData(resp.data.results.map((item) => ({ display_name: `${item.last_name || ''} ${item.first_name || ''} ${item.patronymic || ''}`, value: item.id })));
                setIsNextUsers(resp.data.next);
            }
        })
    };

    const loadMoreUser = () => {
        if (isNextUsers !== null && !loading) {
            setLoading(true);
            userApi.nexpPage(isNextUsers).then((resp) => {
                if (resp.success) {
                    setUserData((prevUser) => {
                        const arr = []
                        resp.data.results?.map(item => {
                            const user = prevUser.find(u => u.value === item.id)
                            if (user === undefined) {
                                arr.push({ display_name: `${item.last_name || ''} ${item.first_name || ''} ${item.patronymic || ''}`, value: item.id })
                            }
                        })
                        return [...prevUser, ...arr]
                    });
                    setIsNextUsers(resp.data.next);
                    setLoading(false);
                }
            })
        }
    };

    useEffect(() => {
        let userIds = []
        Object.values(microdistData.pris_tab).map((formBlock) => {
            formBlock.map((block) => {
                const enforcement_responsible = block['enforcement_responsible']
                const executor = block['executor']
                const responsible = block['responsible']
                if (enforcement_responsible)
                    userIds.push(enforcement_responsible)
                if (executor)
                    userIds.push(executor)
                if (responsible)
                    userIds.push(responsible)
            })
        })

        for (const id of userIds) {
            userApi.getById({ urlParams: id })
                .then((resp) => {
                    if (resp.success) {
                        setUserData((prevUser) => {
                            const user = prevUser.find(u => u.value === id)
                            if (user)
                                return prevUser
                            else
                                return [
                                    ...prevUser,
                                    { display_name: `${resp.data.last_name || ''} ${resp.data.first_name || ''} ${resp.data.patronymic || ''}`, value: resp.data.id }
                                ]
                        })
                    }
                })
        }

    }, [])

    useEffect(() => {
        loadMoreUser()
    }, [])

    const searchSelect = {
        onScroll: (e) => loadMoreUser(),
        onSearch: (e) => onSearchUser(e),
        options: useMemo(() => userData.length > 0 ? userData : [{}], [userData]),
        loadingScroll: loading
    }

    const formBlocks = [
        {
            title: 'Судебные дела', group: 'court_cases', textAddButton: 'Добавить судебное дело', searchSelect: {
                "enforcement_responsible": searchSelect,
                executor: searchSelect
            }
        },
        {
            title: 'Претензии', group: 'claims', textAddButton: 'Добавить претензию', searchSelect: {
                responsible: searchSelect,
                executor: searchSelect
            }
        },
        {
            title: 'Соглашения', group: 'agreements', textAddButton: 'Добавить соглашение', searchSelect: {
                responsible: searchSelect
            }
        }
    ];

    // Функция для обработки изменения ввода
    const handlePrisField = (group, value) => {
        const updatedData = { ...microdistData?.pris_tab };
        updatedData[group] = value;
        dispatch(setDataPressItem(['pris_tab', updatedData]));
    };

    const getOptions = (group) => {
        switch (group) {
            case 'agreements': return optionsАgreements;
            case 'claims': return optionsClaim;
            case 'court_cases': return optionsCourtCases;
            default: return [];
        }
    };

    const updateDistrict = () => {
        let hasError = false;
        dispatch(setDataPressItem(['tab', 'pris_tab']));

        if (!hasError) {
            landPlot.update({ urlParams: id + '/', body: microdistData }).then((resp) => {
                if (resp.success) {
                    setIsComplete('Данные обновлены')
                    dispatch(setNeedsRerender(!needsRerender.needsRerender));
                } else {
                    setFormError('Ошибка при сохранении данных')
                    console.error(resp)
                }
            });
        }
    };

    return (
        <div className='continerLandplotPage'>
            {formError &&
                <ErrorMessage
                    type={'error'}
                    message={formError}
                    onClose={() => setFormError('')}
                />
            }
            {
                isComplete &&
                <ErrorMessage
                    message={isComplete}
                    onClose={() => setIsComplete('')}
                    type={'success'}
                />
            }
            <div className='inputContainerCol'>
                {formBlocks.map(({ title, group, textAddButton, searchSelect }) => (
                    <div key={group} className='col-3'>
                        <span className='PirsTab__block-title'>{title}</span>
                        <FormBlock
                            title={title}
                            dataOptions={getOptions(group)}
                            onChange={(data) => handlePrisField(group, data)}
                            initData={microdistData?.pris_tab?.[group]}
                            textAddButton={textAddButton}
                            searchSelect={searchSelect}
                        />
                    </div>
                ))}
            </div>

            {isRedact &&
                <Buttons text={'Сохранить'} className={'saveButton'} ico={icons.floppyCheckmark} onClick={updateDistrict} />
            }
        </div>
    );
};

export default LandploatFormPris;
