import React from 'react'
import { formatDateIntlTimeDate, getDateTime } from '../UI/functions/functions';
import './styles.scss'

const LandploatFormHistory = ({ stagesData, stagesOption }) => {
    const reversedStagesData = [...stagesData].reverse();
    return (
        <div className='containerHistory'>
            {reversedStagesData.map((item) => {
                return (
                    <div className='historyContain'>
                        <h1 className='dateHistory'>{formatDateIntlTimeDate(item.date)}</h1>
                        <p class="smaller-width">пользователь <b>{item?.user?.first_name + ' ' + item?.user?.last_name}</b></p>
                        {
                            typeof item.changes === 'string' ?
                                <p class="smaller-width">произвёл действие <b>{item.changes}</b></p>
                                :
                                typeof item.changes === 'object' ?

                                    <div className='hsitoryContainText'>
                                        <p class="smaller-width">{item.changes.sub_name && <b>в {item.changes.sub_name}</b>} в поле <b>{item.changes.name}</b></p>
                                        {
                                            (item.changes.old_val === null || item.changes.old_val === 'None' || item.changes.old_val.length === 0) ?
                                                <>
                                                    <p>установил значение на <b>{item.changes.new_val}</b></p>

                                                </>
                                                :
                                                item.changes.new_val == '' ?
                                                    <b>удалил значение</b>
                                                    :
                                                    <>
                                                        <p class="smaller-width">изменил значение с <b>{item.changes.old_val}</b></p>
                                                        <p class="smaller-width">на <b>{item.changes.new_val}</b></p>
                                                    </>
                                        }

                                    </div>


                                    :
                                    null
                        }
                    </div>
                )
            })}
        </div>
    )
}

export default LandploatFormHistory