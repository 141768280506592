import React, { useEffect } from 'react';
import './style.scss';
import { useHistory, useLocation } from 'react-router-dom';



const HeaderInfo = ({ name, icon, pageType, buttonContent, toBack = '' }) => {
    const history = useHistory()

    return (
        <header className={`headerInfo`}>
            <div className='headerInfo__title'>
                <img src={icon} onClick={() => toBack ? history.push(toBack) : history.goBack()} />
                <p>{name}</p>
            </div>
            {buttonContent}
        </header>
    );
};

export default HeaderInfo;
