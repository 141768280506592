import React, { Fragment, useEffect, useMemo, useState } from 'react'
import Header from '../../component/Header/Header'
import icons from '../../assets/icons/icons'
import Tables from '../../component/Tables/Tables'
import { fieldToArray } from "../../component/UI/functions/functions";
import LandPlot from "../../api/Landplot/LandPlot";
import { Link } from 'react-router-dom';
import Buttons from '../../component/Buttons/Buttons';
import LandPlotApiRequest from '../../api/Landplot/LandPlot';
import Modal from '../../component/Modal/Modal';
import ToggleButton from '../../component/ToggleButton/ToggleButton';
import FilePicker from '../../component/UI/FilePicker/FilePicker';
import FormInput from '../../component/FormInput/FormInput';
import { updateDataKey } from '../../component/UI/functions/updateDataKey/updateDataKey';
import FileAttached from '../../component/UI/FileAttached/FileAttached';
import { useDispatch, useSelector } from 'react-redux';
import { cleardataReportFilter, setDataReportFilterPressItem } from '../../store/filterReportDataReduces'
import { setLoading } from '../../store/loadingReducer';
import ContentLoader from '../../component/ContentLoader/ContentLoader';
import Loader from '../../component/Loader/Loader';
import ContragentApiRequest from '../../api/Contragent/Contragent';
import groupUser from '../../locales/groupUser';
import ConstructionReportApiRequest from '../../api/ConstructionReport/ConstructionReport';

const ConstructorReportTablePage = () => {

    const userData = useSelector(state => state.user_data.userData);

    const isAdmin = userData?.user?.groups?.some(group => group.name === groupUser.admin);
    const isCadastral = userData?.user?.groups?.some(group => group.name === groupUser.cadastralGroup);
    const isControlBuild = userData?.user?.groups?.some(group => group.name === groupUser.controlBuild);
    const isPaymentDepartment = userData?.user?.groups?.some(group => group.name === groupUser.PaymentDeportament);
    const isAuditor = userData?.user?.groups?.some(group => group.name === groupUser.auditor);

    const [isList, setIsList] = useState()
    const [isFilterOption, setIsFilterOption] = useState()
    const [isMicrodistrictsOptions, setIsMicrodistrictsOptions] = useState()
    const [isMicrodistrictsOptionsCreate, setIsMicrodistrictsOptionsCreate] = useState()
    const [isUpdate, setIsUpdate] = useState(false)
    const [isFilter, setIsFilter] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [isData, setIsData] = useState()
    const [textSearch, setTextSearch] = useState('');
    const [isLoadingButton, setIsLoadingButton] = useState(true);

    const dataReportFilter = useSelector((state) => state.data_reportFilter);
    const dispatch = useDispatch();
    const loading = useSelector(state => state.loading);

    const contragentApi = new ContragentApiRequest();
    const [contragentData, setContragentData] = useState([])
    const [isNextUsers, setIsNextUsers] = useState('')
    const next = isData?.next?.slice(isData.next.indexOf('?') + 1);



    const constructionReport = new ConstructionReportApiRequest();

    useEffect(() => {
        dispatch(setLoading(true));
        // clearFilter();
        constructionReport.options().then((resp) => {
            if (resp.success) {
                setIsList(fieldToArray(resp?.data?.actions.list));
                setIsMicrodistrictsOptions(resp?.data?.actions.discharge);
                setIsMicrodistrictsOptionsCreate(resp?.data?.actions.create);
                setIsFilterOption(resp?.data?.actions?.filter);



                const searchParams = new URLSearchParams(window.location.search);

                // Извлечь значения limit и offset из строки запроса
                const offset = searchParams.get('offset');
                const search = searchParams.get('search');

                const queryString = dataReportFilter && Object.keys(dataReportFilter)
                    .map((key) => `${key}=${dataReportFilter[key]}`)
                    .join('&');

                constructionReport.list({ urlParams: `?limit=${Number(offset) + 25}&${queryString}&search=${search ? search : ''}` }).then((resp) => {
                    if (resp.success) {
                        // if (Object.keys(dataReportFilter).length !== 0) {
                        //     // `dataReportFilter` является пустым объектом
                        //     filterItems();
                        //   } else {
                        //     clearFilter();
                        //   }
                        setIsData(resp.data);
                        dispatch(setLoading(false));
                        setIsLoading(false)
                    } else {
                        return;
                    }
                });

            } else {
                return;
            }
        });


    }, [isUpdate]);


    const loadMoreItems = () => {
        setIsLoadingButton(false);

        const offset = new URLSearchParams(isData.next).get('offset');

        constructionReport.list(Object.keys(dataReportFilter).length !== 0 ? { urlParams: `?${next}` } : { urlParams: `?offset=${offset}` }).then((resp) => {
            if (resp.success) {
                setIsData((prevData) => ({
                    ...prevData,
                    results: [...prevData.results, ...resp.data.results],
                    next: resp.data.next,
                    count: resp.data.count,
                    previous: resp.data.previous
                }));
                // Сохранить данные пагинации в строке браузера
                const newUrl = window.location.pathname + `?offset=${offset}`;
                window.history.pushState({ path: newUrl }, '', newUrl);
            }
        });
    };

    useEffect(() => {

        next != null ? setIsLoadingButton(true) : setIsLoadingButton(false)
    }, [isData])


    const filterItems = () => {
        dispatch(setLoading(true));
        const queryParams = fieldToArray(dataReportFilter).map(item => `${item.key}=${item.value}`).join('&');
        const urlParams = `?${queryParams}`;

        constructionReport.list({ urlParams }).then(resp => {
            if (resp.success) {
                setIsData((prevData) => ({
                    ...prevData,
                    results: resp.data.results,
                    next: resp.data.next,
                    count: resp.data.count,
                    previous: resp.data.previous
                }));
                dispatch(setLoading(false));
                setIsFilter(false);
            }
        });
    };

    const shouldShowLoadMoreButton = () => {
        return isData?.results?.length >= 0 && isData?.results?.length < isData?.count;
    };

    const clearFilter = () => {
        dispatch(cleardataReportFilter())
        setIsUpdate(true)
        setIsFilter(false)
    }


    const onSearchContragent = (e) => {
        contragentApi.searchContragent(e).then((resp) => {
            if (resp.success) {
                setContragentData(resp.data.results.map((item) => ({ display_name: item.org_name !== null ? item.org_name : item.fio, value: item.id })));
                setIsNextUsers(resp.data.next);
            }
        })
    };

    const loadMoreContragent = () => {
        if (isNextUsers !== null && !loading) {
            setLoading(true);
            contragentApi.NextContragent(isNextUsers).then((resp) => {
                if (resp.success) {

                    setContragentData((prevContragent) => [
                        ...prevContragent,
                        ...resp.data.results.map((item) => ({ display_name: item.org_name !== null ? item.org_name : item.fio, value: item.id }))
                    ]);
                    setIsNextUsers(resp.data.next);
                    setLoading(false);

                }
            })
        }
    };

    useEffect(() => {
        localStorage.setItem('filterReport', JSON.stringify(dataReportFilter || {}))
    }, [dataReportFilter])


    const handleSearch = () => {
        dispatch(setLoading(true));
        constructionReport.list({ urlParams: `?search=${textSearch}` }).then((resp) => {
            if (resp.success) {
                setIsData((prevData) => ({
                    ...prevData,
                    results: resp.data.results,
                    next: resp.data.next,
                    count: resp.data.count,
                    previous: resp.data.previous
                }));
                dispatch(setLoading(false));
                setIsLoading(false)
                // Сохранить данные пагинации в строке браузера
                const newUrl = window.location.pathname + `?search=${textSearch}`;
                window.history.pushState({ path: newUrl }, '', newUrl);
            }

        })
    }

    useMemo(() => {
        loadMoreContragent();
    }, []);

    return (
        <>
            {
                loading ?
                    <Loader />
                    :
                    <div>

                        {
                            isFilter &&
                            <Modal
                                content={
                                    <div className='containerModal filter'>
                                        <h1>Фильтры</h1>
                                        {
                                            fieldToArray(isFilterOption).map((item) => {

                                                // if (item.key === 'sub_status') {
                                                //     if((dataReportFilter['status'] === '' || dataReportFilter['status'] === undefined) || optionsSubStatus.length === 0){
                                                //        return null
                                                //     }
                                                // }
                                                return (
                                                    <FormInput
                                                        subInput={item.value.label}
                                                        value={dataReportFilter[item.key]}
                                                        options={item?.value?.choices
                                                            ? !isAdmin
                                                                ? item.value.choices.filter(data => data.value !== 13 && data.display_name !== "Инвентаризация")
                                                                : item.value.choices
                                                            : item.key === 'contragent'
                                                                ? contragentData.length > 0 ? contragentData : [{}]
                                                                : ''}

                                                        onChange={(e) => { dispatch(setDataReportFilterPressItem([item.key, e])) }}
                                                        onScroll={loadMoreContragent}
                                                        onSearch={(e) => onSearchContragent(e)}
                                                        type={item.value.type}
                                                    />
                                                )
                                            })
                                        }


                                        <div className='modalButtonContainer'>
                                            <Buttons text={'Принять'} onClick={() => { filterItems() }} />
                                            <Buttons text={'Сбросить'} className={'greyButton'} onClick={() => { clearFilter() }} />
                                        </div>
                                    </div>
                                }
                                onClose={() => setIsFilter(false)}
                            />
                        }
                        <Header
                            name={'Конструктор отчетов'}
                            icon={icons.map}
                            buttonContent={
                                // <div className='containerHeaderSearch'>
                                <>
                                    <FormInput
                                        value={textSearch}
                                        onChange={(e) => { setTextSearch(e) }}
                                        placeholder={'Введите текст для поиска'}
                                        style={'searchInput'}
                                        buttonInput
                                        icoButton={icons.search}
                                        styleButton={'buttonSearch'}
                                        onChangeButton={() => { handleSearch() }}
                                    />


                                    <div className='regionContainer'>
                                        <Buttons
                                            circle={fieldToArray(dataReportFilter).length > 0 && fieldToArray(dataReportFilter).length}
                                            ico={icons.Filter}
                                            text={'Фильтры'}
                                            className={'filterButton'}
                                            onClick={() => setIsFilter(true)}
                                        />
                                        {
                                            (isAdmin || isCadastral || isControlBuild || isPaymentDepartment || isAuditor) &&
                                            <Fragment>
                                                <Link to={'/construction-report-create'}>
                                                    <Buttons
                                                        text={'Создать отчет'}
                                                    />
                                                </Link>
                                            </Fragment>
                                        }
                                    </div>
                                </>
                            }
                            lenght={isData?.count}
                        />
                        {

                            <Tables isData={isData?.results} next={isData?.next} isList={isList} type={'report'} loading={loading} isLoadingButton={isLoadingButton} onLoad={() => { shouldShowLoadMoreButton() && (loadMoreItems()) }} />
                        }

                    </div>
            }
        </>

    )
}

export default ConstructorReportTablePage