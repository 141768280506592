export const API_CLIENTS_MODEL = {
    entity: 'clients',
    url: 'clients/',
    methods: {
        map: {
            url: 'map/'
        },
        uploadImage: {
            url: 'upload/'
        },
        stagesSetComplete: {
            url: '/stage_set_complete/'
        },
        createInspection: {
            url: '/create_inspection/'
        },
        discharge: {
            url: 'discharge/'
        }
    },
}