import React, { useRef, useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom';
// import sidebarMedia from '../../locales/ru.json';
// import logoMedia from '../../assets/LogoMedia.svg';
// import LogoMediaMini from '../../assets/LogoMediaMini.svg';
import icons from '../../assets/icons/icons';
import './styles.scss';
import RevokeApiRequest from '../../api/RevokeRefresh/RevokeRefresh';
import apiConfig from '../../api/apiConfig';
import axios from 'axios';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setUpdateSidebar } from '../../store/updateSidebar';
import { useScreenWidthMobile } from '../UI/functions/functions';

const Sidebar = ({ sbData, pageType }) => {

    const [isActive, setIsActive] = useState(() => {
        const storedState = localStorage.getItem('sidebarState');
        return storedState ? storedState : false;
    });
    const dispatch = useDispatch();

    const { pathname } = useLocation();
    const sidebarRef = useRef(null);


    useEffect(() => {
        dispatch(setUpdateSidebar(isActive))
        localStorage.setItem('sidebarState', JSON.stringify(isActive));
    }, [isActive]);


    const active = sbData?.findIndex(e => e.path === pathname);

    const logOut = () => {
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        localStorage.removeItem('refresh')
        // Удаление токена, пользователя и refresh из куки
        // document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        // document.cookie = "user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        // document.cookie = "refresh=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

        window.location.replace(`${apiConfig.urlCollective}logout?next=${apiConfig.urlGeo}`);

    }

    const userData = useSelector(state => state.user_data.userData);
    const isMobile = useScreenWidthMobile();

    useEffect(() => {
        // Use the custom hook and update the state

        setIsActive(!isMobile);
    }, [isMobile]);


    return (
        <>
            <div ref={sidebarRef} className={`sidebar ${isActive ? 'active' : ''}`}>
                <Link to={'/'} className={`sidebar__header ${isActive ? 'miniBar' : ''}`}>
                    <img src={isActive ? icons.Logomini : icons.bikLogo} alt='Logo'></img>
                    <p className={`subText ${isActive && "active"}`}>ГЕО</p>
                </Link>
                <div className='sidebar__container'>
                    <div className='sidebar_center'>
                        <nav className="sidebar__nav">
                            {sbData.map((e, i) => (
                                <Link
                                    to={e.path}
                                    key={i}
                                    style={isActive ? { flexDirection: 'column', justifyContent: "center" } : {}}
                                    className={`${i === active ? 'active' : ''} `}
                                >
                                    <img src={e.ico} alt='Icon' style={isActive ? { marginRight: 0 } : {}}></img>
                                    <span
                                        className={`display ${isActive ? 'hidden' : ''}`}
                                    >
                                        {e.display}
                                    </span>
                                </Link>)

                            )}
                        </nav>
                        <a
                            onClick={() => window.location.replace(`${apiConfig.urlCollective}`)}
                            className={`backCollectButton ${isActive ? 'miniBar' : ''}`}
                        >
                            <img src={icons.ArrowBackCol} alt='Icon'></img>
                            <span
                                className={`display`}
                            >
                                Вернуться<br /> в «Коллектив»
                            </span>
                        </a>
                    </div>
                </div>
                <div className='sidebar__footerLink'>
                <div onClick={() => setIsActive(!isActive)} className={`buttonSide ${isActive && "active"}`}>
                    <img src={icons.backSideBar} className={`button__sideBar ${isActive && "active"}`} alt='Toggle Sidebar'></img>
                </div>
                    <a href='http://collback.bik31.ru/media/app-release.apk' target='_blank' className='linkMobileApp' download={'http://collback.bik31.ru/media/app-release.apk'}>
                        <img src={icons.arrowDown} alt='Icon'></img>
                        <div className='linkMobileText'>
                            <h4>Скачать приложение</h4>
                            <h6>Только для Android</h6>
                        </div>
                    </a>
                    <div className={`footerUserData ${isActive && 'active'}`}>
                        <h3 className='userName'>
                            {`${userData?.user?.last_name || ''} ${userData?.user?.first_name?.charAt(0) || ''}. ${userData?.user?.patronymic?.charAt(0) || ''}.`}
                        </h3>

                        <p className='userProf'>{userData?.user?.post_bik}</p>
                        <p className='userOrg'>{userData?.user?.structure_bik}</p>
                    </div>
                    <div
                        onClick={() => { logOut() }}
                        className='logoutButton'
                    >
                        {isActive ?
                            <img src={icons.logout} alt='Logout'></img>
                            :
                            <p>Выход</p>
                        }

                    </div>
                </div>
            </div>
            <div className={`backdrop ${isActive ? 'active' : ''}`} onClick={() => setIsActive(!isActive)}></div>
        </>
    );

}

export default Sidebar