import React from "react";
import "./styles.scss";
import icons from "../../../assets/icons/icons";

const ErrorMessage = ({ type, message, onClick, onClose }) => {
  const error = type === false || type === "error";
  const success = type === true || type === "success";
  const warning = type === undefined || type === "warn" || type === "warning";
  let className = "error-message";
  if (success) {
    className += " success";
  } else if (error) {
    className += " error";
  } else if (warning) {
    className += " warning";
  }

  return (
    <div className={`${className} animated-error`}>
      <div className="errHeader">
        <object
          type="image/svg+xml"
          data={success ? icons.Success : error ? icons.Err : ''}
          className="svgImage"
        >
          err
        </object>
        <img src={success ? icons.xClosed : icons.xClosed} onClick={onClose} style={{ cursor: "pointer" }} alt="Close"
        />
      </div>
      <p className="errMessage">
        <span>
          {success ? message ? message : "Всё отлично" : error ? "Произошла ошибка: " : "Предупреждение: "}
        </span>
        {!success && message}
      </p>
      {error ? (
        <p onClick={onClick} className="errButton">
          Повторить
        </p>
      ) : (


        <p onClick={onClose} className="errButton">
          Закрыть
        </p>


      )}
    </div>
  );
};

export default ErrorMessage;
