import BaseModelAPI from "../BaseModelAPI";
import { API_PAYMENT_MODEL } from "./const";

class PaymentApiRequest extends BaseModelAPI {
    constructor() {
        super(API_PAYMENT_MODEL.url);
    }
}

export default PaymentApiRequest;
