import { createSlice } from '@reduxjs/toolkit';

const filterState = localStorage.getItem('filterMicro')? JSON.parse(localStorage.getItem('filterMicro')) : {};

export const dataFilter = createSlice({
    name: 'data_filter',
    initialState: filterState,
    reducers: {
        setDataFilterPressItem(state, action) {
            state[action.payload[0]] = action.payload[1];
        },
        cleardataFilter(state) {
            // Обнуляем все данные в срезе
            return {};
        },
    },
});

export const { setDataFilterPressItem, cleardataFilter } = dataFilter.actions;

export default dataFilter.reducer;
