import React, { Fragment, useState } from 'react';
import './styles.scss'
import { fieldToArray, formatDateIntlDate, formatDateIntlDateTime, getFormatedDate, getFormattedStandartDate, getHtmlFormatDate, transformDate } from '../UI/functions/functions';
import icons from '../../assets/icons/icons';
import ImageThumbnail from '../UI/imageThumbnail/imageThumbnail';
import Buttons from '../Buttons/Buttons';
import Modal from '../Modal/Modal';
import FilePicker from '../UI/FilePicker/FilePicker';
import { updateDataKey } from '../UI/functions/updateDataKey/updateDataKey';
import { useDispatch, useSelector } from 'react-redux';
import { setDataPressItem } from '../../store/microdistDataReduces';
import FormInput from '../FormInput/FormInput';
import LandPlotApiRequest from '../../api/Landplot/LandPlot';
import { useParams } from 'react-router-dom';
import { setNeedsRerender } from '../../store/needsRerenderSlice';
import ErrorMessage from '../UI/ErrorMassage/ErrorMassage';

const FormDropDown = ({ number, dataDrop, dataOption, isRedact }) => {

    const [isOpen, setIsOpen] = useState(false);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [modalCopleteOpen, setModalCopleteOpen] = useState(false);
    const [isImageArrow, setIsImageArrow] = useState(false);
    const [isComment, setIsComment] = useState('');
    const [IsViolation, setIsViolation] = useState();
    const [isErr, setIsErr] = useState(false);
    const currentDate = new Date();
    const microdistData = useSelector((state) => state.mocrodist_data);
    const needsRerender = useSelector((state) => state.needsRerender);
    const dispatch = useDispatch();
    const { id } = useParams();
    const landPlot = new LandPlotApiRequest();

    const optionComment = dataOption.child.children.inspections.child.children;
    const optionStage = dataOption.child.children.name.choices;
    const optionFormStage = dataOption.child.children;

    const stagesComplete = () => {
        if (dataDrop.inspections.length === 0) {
            setIsErr("В стадии нет проверок");
        } else {
            const stage6 = microdistData.stages_tab.find(item => item.name === 'STAGE_6');
            const dataInspections = {
                house_address: stage6.status === 'IN_PROGRESS' ? stage6.house_address : null,
                house_area: stage6.status === 'IN_PROGRESS' ? stage6.house_area : null,
                house_delivery_date: stage6.status === 'IN_PROGRESS' ? stage6.house_delivery_date.includes('.')
                    ? transformDate(stage6.house_delivery_date, '.')
                    : stage6.house_delivery_date : null
            };
            landPlot.stagesSetComplete(id, stage6.status === 'IN_PROGRESS' ? dataInspections : null).then((resp) => {
                if (resp.success) {
                    setModalCopleteOpen(false)
                    dispatch(setDataPressItem(['stages_tab', resp.data.stages_tab]));
                }

            })
        }
    }
    const createInspections = () => {
        let hasError = false;

        if ((!isComment || isComment.length === 0)) {
            hasError = true;
        }
        if (dataDrop.is_violation && (!IsViolation || IsViolation.length === 0)) {
            hasError = true;
        }

        if (hasError) {
            setIsErr("Заполните все обязательные поля.");
        } else {
            const stage6 = microdistData.stages_tab.find(item => item.name === 'STAGE_6')

            const dataInspections = {
                name: dataDrop.is_violation ? `Нарушение ${formatDateIntlDateTime(currentDate)}` : `Проверка ${formatDateIntlDateTime(currentDate)}`,
                images: isImageArrow,
                date: getFormattedStandartDate(currentDate),
                comment: isComment,
                is_situation: dataDrop.is_situation,
                is_violation: dataDrop.is_violation,
                violation: dataDrop.is_violation ? IsViolation : null,
                house_address: stage6 ? stage6.house_address : null,
                house_area: stage6 ? stage6.house_area : null,
                house_delivery_date: stage6 ? stage6.house_delivery_date : null
            };

            landPlot.createInspection(id, dataInspections).then((resp) => {
                if (resp.success) {
                    dispatch(setDataPressItem(['stages_tab', resp.data.stages_tab]));
                    setIsOpenModal(false)
                    dispatch(setNeedsRerender(!needsRerender.needsRerender));
                }

            })
        }
    }

    const handleStageRevert = (id) => {
        landPlot.stageRevert(`${id}/`).then((resp) => {
            if (resp.success) {
                dispatch(setNeedsRerender(!needsRerender.needsRerender));
            }
        })
    }

    const handleInputChange = (field, value) => {
        const updatedData = [...microdistData?.stages_tab];
        const updatedStages = { ...updatedData[5] };
        updateDataKey(updatedStages, [field], value);
        updatedData[5] = updatedStages;
        dispatch(setDataPressItem([`stages_tab`, updatedData]));
    };

    return (
        <div>
            {
                isErr &&
                <ErrorMessage
                    message={isErr}
                    onClose={() => setIsErr(false)}
                    type={'error'}
                    onClick={() => {
                        dataDrop.is_violation ? stagesComplete() : createInspections()
                    }}
                />
            }
            {isOpenModal && (
                <Modal onClose={() => setIsOpenModal(false)} content={
                    <div className='containerModalDropDown'>
                        <h1 className='checkTitle'>{`${dataDrop.is_violation ? 'Нарушение' : 'Проверка'
                            } ${formatDateIntlDateTime(currentDate)}`}</h1>
                        <div className='containerInputCheck'>
                            <div className='formContainerModal'>
                                {
                                    dataDrop.is_violation &&
                                    <FormInput
                                        subInput={optionComment.violation.label}
                                        type={optionComment.violation.type}
                                        options={optionComment.violation.choices}
                                        keyData={optionComment.violation.key}
                                        onChange={(e) => setIsViolation(e)}
                                        required={optionComment.violation.required}
                                    />
                                }
                                <FormInput
                                    subInput={optionComment.comment.label}
                                    type={optionComment.comment.type}
                                    options={optionComment.comment.choices}
                                    keyData={optionComment.comment.key}
                                    onChange={(e) => setIsComment(e)}
                                    required={optionComment.comment.required}
                                    textArea={true}
                                />
                                <FilePicker type={'image'} setFiles={(e) => setIsImageArrow(e)} />
                                {isImageArrow && (
                                    <ImageThumbnail imageData={isImageArrow} className={'checkModalImage'} />
                                )}
                            </div>
                            <div className='buttonCheckContainerModal'>
                                <Buttons className={'createButtonGrey'} text={'Отменить'} onClick={() => setIsOpenModal(false)} />
                                <Buttons className={'createButtonGreen'} text={dataDrop.is_violation ? 'Создать нарушение' : 'Создать проверку'} onClick={() => createInspections()} />
                            </div>
                        </div>
                    </div>
                }
                />
            )}
            {modalCopleteOpen &&
                <Modal onClose={() => setModalCopleteOpen(false)} content={
                    <div className='containerModalDropDown'>
                        <h1 className='checkTitle'>Завершение стадии</h1>
                        <div className='containerLAlertModal'>
                            <p className='alertTextModal'><img src={icons.alertCircle}></img>Внимание! Действие необратимо</p>
                            <p className='textAlertModal'>Вы собираетесь отметить стадию завершенной. Этот статус будет закреплен за вашим именем и датой.</p>
                            <div className='buttonCheckContainerModal'>
                                <Buttons className={'createButtonGrey'} text={'Отменить'} onClick={() => setModalCopleteOpen(false)} />
                                <Buttons className={'createButtonGreen'} text={'Завершить стадию'} onClick={() => stagesComplete()} />
                            </div>
                        </div>
                    </div>
                }
                />
            }
            <div className={`dropDownContainer ${dataDrop.status === 'BLOCKED' && 'blockedDrop'}`} onClick={() => { setIsOpen(!isOpen) }}>
                <div className='nameDropDown'>
                    {(() => {
                        switch (dataDrop.status) {
                            case 'IN_PROGRESS':
                                return (
                                    dataDrop.is_violation || dataDrop.is_situation ?
                                        null
                                        :
                                        <p className='circleNumber'>{number}</p>
                                );
                            case 'COMPLETED':
                                return <img src={icons.CheckCircle} alt="Completed" />;
                            default:
                                return <p className='circleNumber'>
                                    <img src={icons.lock} alt="Completed" />
                                </p>;
                        }
                    })()}
                    <h1>{optionStage.find(item => item.value === dataDrop.name)?.display_name || dataDrop.name}</h1>

                </div>
                <div className='dateDropDown'>
                    <div className='containerDate'>
                        <span>План</span>
                        <p>{dataDrop.planned_completion_date ? formatDateIntlDate(dataDrop.planned_completion_date) : ''}</p>
                    </div>
                    <div className='containerDate'>
                        {
                            dataDrop.last_update_datetime &&
                            <>
                                <span>Обновлено</span>
                                <p>{formatDateIntlDateTime(dataDrop.last_update_datetime)}</p>
                            </>
                        }

                    </div>
                    <img src={icons.chevronDown} className={`downImg ${isOpen && 'upImg'}`} alt="Chevron" />
                </div>
            </div>
            {isOpen &&
                <div className='checkContainer'>

                    {dataDrop?.inspections?.map((item) => {
                        return (
                            <div className='stagesInspectionContainer'>
                                <div>
                                    <h1 className='checkTitle'>{item.name}</h1>
                                </div>
                                {dataDrop.is_violation &&
                                    <div className='violationContainer'>
                                        <h1>Нарушение</h1>
                                        {/* <h4>{item.violation}</h4> */}
                                        <h4>{dataOption?.child?.children?.inspections?.child?.children?.violation?.choices?.find(i => i.value === item.violation)?.display_name}</h4>
                                    </div>
                                }
                                <h4 className='checkComment'>{item.comment}</h4>
                                <div>
                                    <ImageThumbnail
                                        imageData={item.images}
                                        upload={item.images}
                                        className={'imageThumbDropDown'}
                                        modal={true}
                                    />
                                </div>
                            </div>
                        )
                    })}

                    {dataDrop.name === 'STAGE_6' &&
                        <div className='formStage'>
                            {
                                fieldToArray(optionFormStage).map((item) => {
                                    if (item.key === 'house_address' || item.key === 'house_area' || item.key === 'house_delivery_date') {
                                        
                                        return (
                                            <FormInput
                                                subInput={item.value.label}
                                                type={item.value.type}
                                                options={item.value.choices}
                                                onChange={(value) => { handleInputChange(item.key, value) }}
                                                keyData={item.key}
                                                required={true}
                                                placeholder={item.value.placeholder}
                                                value={id ? microdistData.stages_tab[5][item.key] : microdistData[item.key] || ''}
                                                checked={id ? microdistData.stages_tab[5][item.key] : microdistData[item.key] || false}
                                                style={'col-1'}
                                            />
                                        )
                                    }
                                })
                            }
                        </div>
                    }

                    {dataDrop.status === 'COMPLETED' ?
                        <>
                            <div className='statusStagesComplete'>
                                <div className='statusStagesContainer'>
                                    <img src={icons.CheckCircle} alt="Completed" />
                                    <p className='statusStagesText'>Стадия завершена</p>
                                </div>
                                <div className='datenameStagesText'>
                                    <p>{formatDateIntlDateTime(dataDrop.last_update_datetime)}</p>
                                </div>

                            </div>
                            <div className='statusStagesComplete'>
                                {isRedact &&
                                    <Buttons className={'repeatButton'} text={'Вернуться к предыдущей стадии'} onClick={() => { handleStageRevert(id) }} />
                                }
                            </div>
                        </>
                        :
                        <div className='buttonCheckContainer'>
                            {isRedact &&
                                <Fragment>
                                    {dataDrop.is_violation ?
                                        <Buttons className={'createButton'} text={'Добавить нарушение'} onClick={() => { setIsOpenModal(true) }} />
                                        :
                                        dataDrop.is_situation ?
                                            <Buttons className={'createButton'} text={'Добавить ситуацию'} onClick={() => { setIsOpenModal(true) }} />
                                            :
                                            <>
                                                <Buttons className={'createButton'} text={'Создать проверку'} onClick={() => { setIsOpenModal(true) }} />
                                                <Buttons className={'createButtonGreen'} text={'Завершить стадию'} onClick={() => { setModalCopleteOpen(true) }} />
                                            </>
                                    }
                                </Fragment>
                            }
                        </div>
                    }
                </div>
            }

        </div>
    )
}

export default FormDropDown