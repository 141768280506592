import axios from 'axios';
import queryString from 'query-string';

import apiConfig from './apiConfig';
import { getCookie } from '../component/UI/functions/functions';

// Получаем значение токена из localStorage
const token = getCookie("token");

const axiosClient = axios.create({
    baseURL: apiConfig.baseUrl,
    headers: {
        // Добавляем заголовок 'Authorization' только если токен не равен null
        ...(token && { 'Authorization': `Bearer ${token}` }),
        'Content-Type': 'application/json'
    },
    paramsSerializer: params => queryString.stringify({ ...params, api_key: apiConfig.apiKey })
});

axiosClient.interceptors.request.use(async (config) => config);

axiosClient.interceptors.response.use((response) => {
    if (response && response.data) {
        return response.data;
    }

    return response;
}, (error) => {
    throw error;
});

export default axiosClient;
