import { createSlice } from '@reduxjs/toolkit';

const filterState = localStorage.getItem('filterReport')? JSON.parse(localStorage.getItem('filterReport')) : {};

export const dataReportFilter = createSlice({
    name: 'data_reportFilter',
    initialState: filterState,
    reducers: {
        setDataReportFilterPressItem(state, action) {
            state[action.payload[0]] = action.payload[1];
        },
        cleardataReportFilter(state) {
            // Обнуляем все данные в срезе
            return {};
        },
    },
});

export const { setDataReportFilterPressItem, cleardataReportFilter } = dataReportFilter.actions;

export default dataReportFilter.reducer;
