import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import './styles.scss'
import Checkbox from '../Checkbox/Checkbox';
import ToggleButton from '../ToggleButton/ToggleButton';
import icons from '../../assets/icons/icons';
import { fieldToArray, useScreenWidthMobile } from '../UI/functions/functions';
import MicroDistrictApiRequest from '../../api/microDistrict/MicroDistrict';
import { cleardataFilter, dataFilter, setDataFilterPressItem } from '../../store/filterDataReduces';
import { useDispatch, useSelector } from 'react-redux';
import FormInput from '../FormInput/FormInput';
import Buttons from '../Buttons/Buttons';
import groupUser from '../../locales/groupUser';
import { useLocation } from 'react-router-dom';

const MapFilter = ({ districtData, activeDistrict, optionsMap, dataStatus, optionMicrodist, onChangeDataMap, type }) => {
    const micropdistPlotInstance = new MicroDistrictApiRequest();
    const dispatch = useDispatch();
    const filterRenderer = useSelector((state) => state.data_filter);
    const [microdistName, setMicrodistName] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const userData = useSelector(state => state.user_data.userData);



    const location = useLocation();

    const isAdmin = userData?.user?.groups?.some(group => group.name === groupUser.admin);
    const isControlBuild = userData?.user?.groups?.some(group => group.name === groupUser.controlBuild);
    const isPaymentDepartment = userData?.user?.groups?.some(group => group.name === groupUser.PaymentDeportament);

    const handleToggleField = (fieldName, isChecked) => {
        dispatch(setDataFilterPressItem([fieldName, isChecked]));
    };

    useEffect(() => {
        optionMicrodist?.map((item) => {
            (item.value === activeDistrict) && setMicrodistName(item.display_name)
        })
    }, [])

    // const countValues = {
    //     INVENTORY: 'inventoried_count',
    //     RESERVATION: 'reservation_count',
    //     PRIMARY_RESERVATION: 'primary_reservation_count',
    //     FOR_SALE: 'for_sale_count',
    //     DEAL: 'deal_count',
    // };
    // const countConstructionValues = {
    //     0: 'inventoried_count',
    //     1: 'reservation_count',
    //     2: 'primary_reservation_count',
    //     3: 'for_sale_count',
    //     4: 'deal_count',
    //     5: 'deal_count',
    // };
    const countCategoryValues = {
        RESIDENTIAL_PLOTS: 'residential_count',
        COMMERCIAL_PLOTS: 'commercial_count',
        AGRICULTURAL_PLOTS: 'agricultural_count',
        MULTIFAMILY_HOUSE_PLOTS: 'multifamily_house_count',
        BLOCKED_RESIDENTIAL_PLOTS: 'blocked_residential_count'
    };

    const isMobile = useScreenWidthMobile();

    const [isMobileActive, setIsMobileActive] = useState(false)
    const [isOpenFilter, setIsOpenFilter] = useState(false)

    useEffect(() => {
        // Use the custom hook and update the state

        setIsMobileActive(!isMobile);
    }, [isMobile]);

    useEffect(() => {
        dispatch(cleardataFilter());
        let updatedStatus = {}; // Создаем объект для хранения значений статуса
        let updatedSubStatus = {}; // Создаем объект для хранения значений статуса
        let updatedStatusType = {}; // Создаем объект для хранения значений статуса
        let updatedStatusConstruction = {}; // Создаем объект для хранения значений статуса
        let updatedStatusObjectOwnership = {}; // Создаем объект для хранения значений статуса
        let updatedStatusObjectType = {}; // Создаем объект для хранения значений статуса

        switch (location.pathname) {
            case '/map':
                fieldToArray(dataStatus).forEach((item) => {
                    updatedStatus[item.value.value] = true; // Устанавливаем все значения статуса в true
                    fieldToArray(item.value['sub-statuses']).forEach((item_sub) => {
                        if (!isAdmin && item_sub.value.value === 13) {
                            updatedSubStatus[item_sub.value.value] = false;
                        } else {
                            updatedSubStatus[item_sub.value.value] = true; // Устанавливаем все значения статуса в true, кроме 13
                        }
                    });
                });
                fieldToArray(optionsMap?.type?.choices).forEach((item) => {
                    updatedStatusType[item.value.value] = true; // Устанавливаем все значения статуса в true
                });
                dispatch(setDataFilterPressItem(['status', updatedStatus]));
                dispatch(setDataFilterPressItem(['sub_status', updatedSubStatus]));
                dispatch(setDataFilterPressItem(['type', updatedStatusType]));
                break;

            case '/controlsMap':
                fieldToArray(dataStatus).forEach((item) => {
                    updatedStatus[item.value.value] = true; // Устанавливаем все значения статуса в true
                    fieldToArray(item.value['sub-statuses']).forEach((item_sub) => {
                        if (!isAdmin && item_sub.value.value === 13) {
                            updatedSubStatus[item_sub.value.value] = false;
                        } else {
                            updatedSubStatus[item_sub.value.value] = true; // Устанавливаем все значения статуса в true, кроме 13
                        }
                    });
                });

                fieldToArray(optionsMap?.construction_stages?.choices).forEach((item) => {
                    updatedStatusConstruction[item.value.value] = true; // Устанавливаем все значения статуса в true
                });

                dispatch(setDataFilterPressItem(['sub_status', updatedSubStatus]));
                dispatch(setDataFilterPressItem(['construction_stages', updatedStatusConstruction]));
                break;

            case '/object-map':
                fieldToArray(optionsMap?.object_ownership?.choices).forEach((item) => {
                    updatedStatusObjectOwnership[item.value.value] = true; // Устанавливаем все значения статуса в true
                });
                fieldToArray(optionsMap?.object_type?.choices).forEach((item) => {
                    updatedStatusObjectType[item.value.value] = true; // Устанавливаем все значения статуса в true
                });
                dispatch(cleardataFilter());
                dispatch(setDataFilterPressItem(['object_ownership', updatedStatusObjectOwnership]));
                dispatch(setDataFilterPressItem(['object_type', updatedStatusObjectType]));
                break;

            default:
                break;
        }
    }, [optionsMap]);

    useLayoutEffect(() => {

        const queryParams = {};

        Object.keys(filterRenderer).forEach(key => {
            const value = filterRenderer[key];
            if (typeof value === 'object') {
                const innerKeys = Object.keys(value).filter(innerKey => value[innerKey] === true);
                if (innerKeys.length > 0) {
                    queryParams[key] = innerKeys;
                }
            } else if (value === true) {
                queryParams[key] = true
            }
        });

        const queryParamsString = Object.keys(queryParams).map(key => {
            if (Array.isArray(queryParams[key])) {
                const innerKeyString = queryParams[key].join(",");
                return `${key}=${innerKeyString}`;
            } else {
                if (key === 'is_control_department') {
                    return `${key}=${filterRenderer.is_control_department}`
                } else {
                    return `${key}`;
                }

            }
        });

        const urlParams = queryParamsString.join('&');
        const finalUrl = `?${urlParams}`;
        switch (location.pathname) {
            case '/object-map':
                micropdistPlotInstance.mapObjectApiFilter(activeDistrict, finalUrl).then(resp => {
                    if (resp.success) {
                        try {

                            onChangeDataMap(resp.data);
                        } catch (error) {
                            console.error(error);
                        }
                    }
                });
                break;
            case '/controlsMap':
                micropdistPlotInstance.mapStagesApiFilter(activeDistrict, finalUrl).then(resp => {
                    if (resp.success) {
                        try {
                            onChangeDataMap(resp.data);
                        } catch (error) {
                            console.error(error);
                        }
                    }
                });
                break;
            default:
                micropdistPlotInstance.mapApiFilter(activeDistrict, finalUrl).then(resp => {
                    if (resp.success) {
                        try {
                            onChangeDataMap(resp.data);
                        } catch (error) {
                            console.error(error);
                        }
                    }
                });
                break;
        }
    }, [filterRenderer])

    return (
        <div className={`mapFilterContainer ${isOpenFilter && 'openFilter'}`}>
            {isMobileActive &&
                <div className='buttonMobileilter'>
                    <Buttons ico={icons.Filter} text={'Фильтры'} onClick={() => { setIsOpenFilter(!isOpenFilter) }} />
                </div>
            }

            <div className={`modalCheck ${isOpenFilter && 'openFilter'}`}>

                {(location?.pathname === '/object-map') ?
                    <div className='containerFilterCheckbox'>
                        <h1 className='titleGroupFilter'>{optionsMap?.object_ownership?.label}</h1>
                        {fieldToArray(optionsMap?.object_ownership?.choices).map((item, index) => {
                            const isChecked = filterRenderer?.object_ownership && filterRenderer.object_ownership[item.value.value] === false;

                            return (
                                <div >
                                    <Checkbox
                                        className={`containerStages__${item.key}`}
                                        key={index}
                                        id={item.value.value}
                                        label={item.value.display_name}
                                        checked={!isChecked}
                                        onChange={(e) => {
                                            const updatedStatus = {
                                                ...filterRenderer.object_ownership,
                                                [item.value.value]: isChecked
                                            };
                                            dispatch(setDataFilterPressItem(['object_ownership', updatedStatus]));
                                        }}
                                        count={districtData?.filters_count?.object_ownership ? districtData?.filters_count?.object_ownership[item?.key]?.count : 0}

                                    />
                                    {/* <p>{districtData.inventoried_count}</p> */}
                                </div>
                            );
                        })}
                        <h1 className='titleGroupFilter'>{optionsMap?.object_type?.label}</h1>
                        {fieldToArray(optionsMap?.object_type?.choices).map((item, index) => {
                            const isChecked = filterRenderer?.object_type && filterRenderer.object_type[item.value.value] === false;

                            return (
                                <div >
                                    <Checkbox
                                        className={`containerStages__${item.key}`}
                                        key={index}
                                        id={item.value.value}
                                        label={item.value.display_name}
                                        checked={!isChecked}
                                        onChange={(e) => {
                                            const updatedStatus = {
                                                ...filterRenderer.object_type,
                                                [item.value.value]: isChecked
                                            };
                                            dispatch(setDataFilterPressItem(['object_type', updatedStatus]));
                                        }}
                                        count={districtData?.filters_count?.object_type ? districtData?.filters_count?.object_type[item?.key]?.count : 0}

                                    />
                                    {/* <p>{districtData.inventoried_count}</p> */}
                                </div>
                            );
                        })}

                        {/* <h1 className='titleGroupFilter'>{optionsMap?.object_type?.label}</h1>
                        <Checkbox
                            className={`isControl`}
                            key={optionsMap?.object_type}
                            id={optionsMap?.object_type}
                            label={optionsMap?.object_type?.label}
                            checked={filterRenderer.object_type}
                            onChange={(e) => {
                                dispatch(setDataFilterPressItem(['is_control_department', !filterRenderer.is_control_department]));
                            }}

                        /> */}

                    </div>
                    : (location?.pathname === '/controlsMap') ?
                        <div className='containerFilterCheckbox'>
                            <h1 className='titleGroupFilter'>{optionsMap?.construction_stages?.label}</h1>
                            {fieldToArray(optionsMap?.construction_stages?.choices).map((item, index) => {
                                const isChecked = filterRenderer?.construction_stages && filterRenderer.construction_stages[item.value.value] === false;

                                return (
                                    <div >
                                        <Checkbox
                                            className={`containerStages__${item.key}`}
                                            key={index}
                                            id={item.value.value}
                                            label={item.value.display_name}
                                            checked={!isChecked}
                                            onChange={(e) => {
                                                const updatedStatus = {
                                                    ...filterRenderer.construction_stages,
                                                    [item.value.value]: isChecked
                                                };
                                                dispatch(setDataFilterPressItem(['construction_stages', updatedStatus]));
                                            }}
                                            count={districtData?.stages_count ? districtData?.stages_count[item?.key]?.count : 0}

                                        />
                                        {/* <p>{districtData.inventoried_count}</p> */}
                                    </div>
                                );
                            })}

                            <h1 className='titleGroupFilter'>{optionsMap?.is_control_department?.label}</h1>
                            <Checkbox
                                className={`isControl`}
                                key={optionsMap?.is_control_department}
                                id={optionsMap?.is_control_department}
                                label={optionsMap?.is_control_department?.label}
                                checked={filterRenderer.is_control_department}
                                onChange={(e) => {
                                    dispatch(setDataFilterPressItem(['is_control_department', !filterRenderer.is_control_department]));
                                }}

                            />
                        </div>
                        :
                        <div className='containerFilterCheckbox'>
                            <h1 className='titleGroupFilter'>Статус участка</h1>
                            {dataStatus && fieldToArray(dataStatus).map((item, index) => {
                                const isChecked = filterRenderer?.status && filterRenderer.status[item.value.value] === false;
                                return (
                                    <div className='conainerStatusSub'>
                                        <Checkbox
                                            key={index}
                                            id={item.value.value}
                                            label={item.value.display_name}
                                            checked={!isChecked}
                                            onChange={(e) => {
                                                const updatedStatus = {
                                                    ...filterRenderer.status,
                                                    [item.value.value]: isChecked
                                                };
                                                dispatch(setDataFilterPressItem(['status', updatedStatus]));
                                            }}
                                            count={districtData?.statuses_count ? districtData?.statuses_count.find((el) => el.id === item.value.value)?.count || 0 : 0}
                                        />


                                        {filterRenderer.status && filterRenderer.status[item.value.value] && (
                                            <div className='containerSubStatus'>
                                                {fieldToArray(item?.value?.['sub-statuses']).map((item_sub, index) => {
                                                    const isChecked = filterRenderer?.sub_status && filterRenderer.sub_status[item_sub.value.value] === false;
                                                    
                                                    if (!isAdmin && item_sub.value.value === 13) {
                                                        return;
                                                    }
                                                    return (
                                                        <Checkbox
                                                            key={index}
                                                            id={`key_${item_sub.value.value}`}
                                                            label={item_sub.value.display_name}
                                                            checked={!isChecked}
                                                            onChange={(e) => {
                                                                const updatedStatus = {
                                                                    ...filterRenderer.sub_status,

                                                                    [item_sub.value.value]: isChecked
                                                                };
                                                                dispatch(setDataFilterPressItem(['sub_status', updatedStatus]));
                                                            }}
                                                            count={districtData?.sub_statuses_count ? districtData?.sub_statuses_count.find((el) => el.id === item_sub.value.value)?.count || 0 : 0}
                                                        />
                                                    );
                                                })}
                                            </div>
                                        )}

                                    </div>
                                );
                            })}
                        </div>

                }
                {/* {(location.pathname === '/map')&& 
                        <div>
                            <h1 className='titleGroupFilter'>Доп. статус</h1>
                            {fieldToArray(optionsMap?.sub_status).map((item) => {
                                const isChecked = filterRenderer?.sub_status && filterRenderer.sub_status[item.value.value] === false; 
                                return (
                                    <Checkbox
                                        key={`key_${item.value.value}`}
                                        id={`key_${item.value.value}`}
                                        label={item.value.display_name}
                                        checked={!isChecked}
                                        onChange={(e) => {
                                            const updatedStatus = {
                                                ...filterRenderer.sub_status,
                                                [item.value.value]: isChecked
                                            };
                                            dispatch(setDataFilterPressItem(['sub_status', updatedStatus]));
                                        }}

                                        count={districtData?.sub_statuses_count ? districtData?.sub_statuses_count[item.key]?.count || 0 : 0}
                                    />
                                );
                            })}
                        </div>
                    } */}
                {(location.pathname === '/map') &&
                    <div className='containerCategory'>
                        <h1 className='titleGroupFilter'>Категория</h1>
                        {fieldToArray(optionsMap?.type?.choices).map((item, index) => {
                            const isChecked = filterRenderer?.type && filterRenderer.type[item.value.value] === false;
                            return (
                                <Checkbox
                                    key={index}
                                    id={item.value.value}
                                    label={item.value.display_name}
                                    checked={!isChecked}
                                    onChange={(e) => {
                                        const updatedStatus = {
                                            ...filterRenderer.type,
                                            [item.value.value]: isChecked
                                        };
                                        dispatch(setDataFilterPressItem(['type', updatedStatus]));
                                    }}

                                    count={districtData ? districtData[countCategoryValues[item.value.value]] || 0 : 0}
                                />
                            );
                        })}
                    </div>
                }


                {(location.pathname === '/map') &&
                    <div className='toggleContainerMapFilter'>
                        <h1 className='titleGroupFilter'>Инженерные сети</h1>
                        {fieldToArray(optionsMap).map((item, index) => {
                            if (item.key === 'status' || item.key === 'type' || item.key === 'sub_status') {
                                return null
                            }
                            return (

                                <FormInput
                                    key={index}
                                    subInput={item.value.label}
                                    type={item.value.type}
                                    options={item.value.choices}
                                    onChange={handleToggleField}
                                    styleContainer={'containerToggle'}
                                    classesTextToggle={'toggleText'}
                                    keyData={item.key}
                                    required={item.value.required}
                                    value={filterRenderer[item.key]}
                                    checked={filterRenderer[item.key]}
                                />

                            )

                        })}
                    </div>
                }

            </div>
            {/* <div className='microdistrictContainer'>
                <img src={icons.geosBik}></img>
                <div className='microdistrictText'>
                    <p>микрорайон</p>
                    <h1 className='smaller-width'>{microdistName}</h1>
                </div>
            </div> */}
        </div>
    )
}

export default MapFilter