export const API_STATUSES_MODEL = {
    entity: 'statuses',
    url: 'statuses/',
    methods: {
        subStatuses: {
            url: 'sub-statuses/'
        },
        allStatuses: {
            url: 'all-sub-statuses/'
        }
    },
}