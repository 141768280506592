import React from 'react';
import './styles.scss';

const ToggleButton = ({ onToggle, text, formDataKey, styleSwitch, classesText, styleRound, checked, styleContainer }) => {
    const onChangeValue = (isChecked) => {
        onToggle(formDataKey, isChecked);
    };


    return (
        <div className={`flex ${styleContainer}`}>
            <label className="switch">
                <input type="checkbox" onChange={(e) => onChangeValue(e.target.checked)} checked={checked} />
                <span className={`slider round ${styleSwitch} ${styleRound}`}>

                </span>
            </label>

            <p className={classesText ? 'checkboxText ' + classesText : 'checkboxText'}>{text}</p>
        </div>
    );
};

export default ToggleButton;
