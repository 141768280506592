import BaseModelAPI from "../BaseModelAPI";
import apiConfig from "../apiConfig";
import axiosClient from "../axiosClient";
import { API_REVOKE_MODEL } from "./const";

class RevokeApiRequest extends BaseModelAPI {
    constructor() {
        super(API_REVOKE_MODEL.url, apiConfig.baseUrlContragent);
    }

    async logoutRevoke(data) {
        return this.makeRequest(axiosClient.post, { body: data });
    }
}

export default RevokeApiRequest;
