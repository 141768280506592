import React from 'react';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import XHR from 'i18next-xhr-backend';

i18next
    .use(XHR)
    .init({
        lng: 'ru', // Устанавливает язык по умолчанию
        fallbackLng: 'ru',
        debug: false, // Включает отладочные сообщения
        // backend: {
        //   loadPath: '/locales/{{lng}}/translation.json', // Путь к языковым файлам
        // },
    });

function AppContainer({ children }) {
    return (
        <I18nextProvider i18n={i18next}>
            {children}
        </I18nextProvider>
    );
}

export default AppContainer;
