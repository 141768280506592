import { FileUpload } from 'primereact/fileupload';
import React, { useState } from 'react'
import apiConfig from '../../api/apiConfig';
import './style.scss'
import LandPlotApiRequest from '../../api/Landplot/LandPlot';
import ErrorMessage from '../UI/ErrorMassage/ErrorMassage';

const IntegrationComponent = ({ item }) => {
  const request = new LandPlotApiRequest()
    , [downloadLoading, setDownloadLoading] = useState(false)
    , [downloadError, setDownloadError] = useState('')



  const downloadLink = (fileName, url) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const uploadFiles = (event) => {
    const response = JSON.parse(event.xhr.response);

    // Construct the file URL using the base URL and the report filename
    const fileUrl = `${apiConfig.baseUrlMedia}media/${response.report}`;

    downloadLink(response.report, fileUrl)
  }

  const downloadFile = (e) => {
    setDownloadLoading(true)
    setDownloadError('')
    request.getById({ method: 'generate_excel_report/' })
      .then(resp => {
        if (resp.success) {
          downloadLink('Отчет', `${apiConfig.baseUrlMedia}media/${resp.data.file_path}`) // пример ответа:  {file_path: "reports/land_plot_report_20240822_104736.xlsx"}
        } else {
          setDownloadError('Возникла ошибка при генерации отчета')
        }
        setDownloadLoading(false)
      })
  }

  return (
    <div className='integrationContainer'>
      {downloadError &&
        <ErrorMessage
          type={'error'}
          message={downloadError}
          onClose={() => setDownloadError('')}
        />
      }
      {downloadLoading &&
        <ErrorMessage
          type={'warning'}
          message={'Отчет генерируется. Это может занять 5-10 минут. Не закрывайте и не перезагружайте страницу'}
          onClose={() => setDownloadError('')}
        />
      }

      <p>{item.name}</p>
      <div style={{display: 'flex', flexDirection: 'row', gap: '4rem'}}> 
        <button onClick={downloadFile} className={'btn-secondary _sm'} disabled={downloadLoading} ><span>{downloadLoading ? 'Генерация отчета...' : 'Скачать'}</span></button>
        <FileUpload mode="basic" name="file" url={`${apiConfig.baseUrl}${item.link}`} accept="xml/*" maxFileSize={10000000} onUpload={uploadFiles} auto chooseLabel='Загрузить' className={'buttonIntegration'} />
      </div>
    </div>
  )
}

export default IntegrationComponent