import { useEffect, useState } from "react"

const getDateObject = (string = '') => {
  const r_ru = /^([0-2][0-9]|3[01])\.(0[1-9]|1[0-2])\.(\d{4})$/
  if (r_ru.test(string)) {
    const date_arr = string.split('.')
    const new_date = `${date_arr[2]}-${date_arr[1]}-${date_arr[0]}`
    return new Date(new_date)
  } else {
    return string ? new Date(string) : new Date();
  }
}

const getFormatedDate = (date) => {
  let formated = ''
  const get_date = getDateObject(date)
  const day = '00' + get_date.getDate()
  const month = '00' + (get_date.getMonth() + 1)
  const year = get_date.getFullYear()
  formated = `${day.substr(-2)}-${month.substr(-2)}-${year}`
  return formated
}

function getFormattedStandartDate(dateString) {
  let formated = ''
  const get_date = getDateObject(dateString)
  const day = '00' + get_date.getDate()
  const month = '00' + (get_date.getMonth() + 1)
  const year = get_date.getFullYear()
  formated = `${day.substr(-2)}.${month.substr(-2)}.${year}`
  return formated
}

const getHtmlFormatDate = (dateString) => {
  let formated = '';
  const get_date = getDateObject(dateString);
  const formattedDay = '00' + get_date.getDate();
  const formattedMonth = '00' + (get_date.getMonth() + 1); // Add 1 to the month
  const formattedYear = get_date.getFullYear();

  formated = `${formattedYear}-${formattedMonth.substr(-2)}-${formattedDay.substr(-2)}`;
  return formated;
};

const getDateTime = date => {
  const get_date = new Date(date)
  const day = '00' + get_date.getDate()
  const month = '00' + (get_date.getMonth() + 1)
  const year = get_date.getFullYear()
  const hours = '00' + get_date.getHours()
  const minutes = '00' + get_date.getMinutes()
  const dateStr = `${day.substr(-2)}.${month.substr(-2)}.${year} ${hours.substring(2)}:${minutes.substring(2)}`
  return dateStr
}

function fieldToArray(fields) {
  if (!fields) {
    return [];
  }

  const fieldEntries = Object.entries(fields);

  if (fieldEntries.length === 0) {
    return [];
  }

  const resultArray = fieldEntries.map(([key, value]) => ({ key, value }));
  return resultArray;
}

export default function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) {
    return '0';
  } else {
    var k = 1024;
    var dm = decimals < 0 ? 0 : decimals;
    var sizes = ['байт', 'КБ', 'МБ', 'ГБ', 'ТБ'];
    var i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}

const transformDate = (inputDate, split) => {
  const parts = inputDate.split(`${split}`);

  if (parts.length === 3) {
    const day = parts[0];
    const month = parts[1];
    const year = parts[2];

    return `${year}-${month}-${day}`;
  } else {
    return 'Invalid Date';
  }
}

// Получение значения куки по имени
function getCookie(name) {
  const cookieName = `${name}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');

  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(cookieName) === 0) {
      return cookie.substring(cookieName.length, cookie.length);
    }
  }
  return null;
}

//проверка на массив массивов
function hasNestedArray(arr) {
  for (let i = 0; i < arr.length; i++) {
    if (Array.isArray(arr[i])) {
      return true; // Если элемент массив, вернуть true
    }
  }
  return false; // Если ни один элемент не является массивом, вернуть false
}

function hasDoubleNestedArray(arr) {
  for (let i = 0; i < arr.length; i++) {
    if (Array.isArray(arr[i])) {
      for (let j = 0; j < arr[i].length; j++) {
        if (Array.isArray(arr[i][j])) {
          return true; // Если найден двойной вложенный массив, вернуть true
        }
      }
    }
  }
  return false; // Если двойной вложенный массив не найден, вернуть false
}

function hasTripleNestedArray(arr) {
  for (let i = 0; i < arr.length; i++) {
    if (Array.isArray(arr[i])) {
      for (let j = 0; j < arr[i].length; j++) {
        if (Array.isArray(arr[i][j])) {
          for (let k = 0; k < arr[i][j].length; k++) {
            if (Array.isArray(arr[i][j][k])) {
              return true; // Если найден тройной вложенный массив, вернуть true
            }
          }
        }
      }
    }
  }
  return false; // Если тройной вложенный массив не найден, вернуть false
}

const useScreenWidthMobile = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const thresholdWidth = 600;

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    // Subscribe to the window resize event
    window.addEventListener('resize', handleResize);

    // Unsubscribe from the event when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array means this effect runs once, similar to componentDidMount

  // Return the result based on the screen width
  return screenWidth >= thresholdWidth;
};

const formatDateIntlTimeDate = (dateString) => {
  let date = getDateObject(dateString)

  // Опции для форматирования времени
  const timeOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: false, // Использовать 24-часовой формат
  };

  // Опции для форматирования даты
  const dateOptions = {
    day: "numeric",
    month: "long",
    year: "numeric",
  };

  //@ts-ignore
  const time = new Intl.DateTimeFormat("ru-RU", timeOptions).format(date);
  //@ts-ignore
  const dateComplete = new Intl.DateTimeFormat("ru-RU", dateOptions).format(
    date
  );

  // Возвращаем объединенную строку
  return `${time} ${dateComplete}`;
};
const formatDateIntlDateTime = (dateString) => {
  let date = getDateObject(dateString)

  // Опции для форматирования времени
  const timeOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: false, // Использовать 24-часовой формат
  };

  // Опции для форматирования даты
  const dateOptions = {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  };

  //@ts-ignore
  const time = new Intl.DateTimeFormat("ru-RU", timeOptions).format(date);
  //@ts-ignore
  const dateComplete = new Intl.DateTimeFormat("ru-RU", dateOptions).format(
    date
  );

  // Возвращаем объединенную строку
  return `${dateComplete} ${time}`;
};

const formatDateIntlDate = (dateString) => {
  let date = getDateObject(dateString)


  // Опции для форматирования даты
  const dateOptions = {
    day: "numeric",
    month: "long",
    year: "numeric",
  };

  //@ts-ignore
  const dateComplete = new Intl.DateTimeFormat("ru-RU", dateOptions).format(
    date
  );

  // Возвращаем объединенную строку
  return `${dateComplete}`;
};

export { getFormatedDate, getFormattedStandartDate, getDateTime, fieldToArray, transformDate, getHtmlFormatDate, getCookie, hasNestedArray, hasDoubleNestedArray, useScreenWidthMobile, hasTripleNestedArray, formatDateIntlTimeDate, formatDateIntlDate, formatDateIntlDateTime }