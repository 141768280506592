import { createSlice } from '@reduxjs/toolkit';

const filterState = localStorage.getItem('filterObject')? JSON.parse(localStorage.getItem('filterObject')) : {};

export const dataObjectFilter = createSlice({
    name: 'data_objectFilter',
    initialState: filterState,
    reducers: {
        setDataReportFilterPressItem(state, action) {
            state[action.payload[0]] = action.payload[1];
        },
        cleardataObjectFilter(state) {
            // Обнуляем все данные в срезе
            return {};
        },
    },
});

export const { setDataReportFilterPressItem, cleardataObjectFilter } = dataObjectFilter.actions;

export default dataObjectFilter.reducer;
