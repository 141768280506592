
import routesConfig from './routesConfig';

//Тут особо ничего не менять (только расширять при необходимости), автоматически строится сайдбар в зависимости от указанных групп

const generateSidebarData = (userData) => {
    const { user } = userData || {};
    const userGroups = user?.groups?.map(group => group.name) || [];
    let sbMedia = [];
  
    userGroups.forEach(group => {
      if (routesConfig[group]) {
        sbMedia = sbMedia.concat(routesConfig[group].sidebar);
      }
    });
  
    return sbMedia;
  };
  
  export default generateSidebarData;