import React, { Fragment, useEffect, useState } from 'react';
import './styles.scss';
import FormInput from '../FormInput/FormInput';
import Buttons from '../Buttons/Buttons';
import icons from '../../assets/icons/icons';
import { useDispatch, useSelector } from 'react-redux';
import { clearDataMicrodist, setDataPressItem } from '../../store/microdistDataReduces';
import { useHistory, useParams } from 'react-router-dom';
import { fieldToArray } from '../UI/functions/functions';
import ErrorMessage from '../UI/ErrorMassage/ErrorMassage';
import GeoObjectReportApiRequest from '../../api/geoobjectReport/geoobjectReport';
import ImageThumbnail from '../UI/imageThumbnail/imageThumbnail';
import FilePicker from '../UI/FilePicker/FilePicker';
import ModalMap from '../ModalMap/ModalMap';

const GeoObjectContain = ({ data, isRedact }) => {
    const dispatch = useDispatch()
    const [dataOption, setOptions] = useState([])
    const [openModal, setOpenModal] = useState(false)
    const [isErr, setIsErr] = useState(false)
    const reportData = useSelector((state) => state.mocrodist_data)

    const geoobjectReport = new GeoObjectReportApiRequest();
    const [fields, setFields] = useState([
        { key: `fields_1`, value: { label: `Поле 1`, type: 'text', required: false } }
    ]);

    const history = useHistory();
    const { id } = useParams();

    useEffect(() => {
        geoobjectReport.options().then((resp) => {
            const options = fieldToArray(resp.data.actions.create);
            setOptions(options);
        });

    }, []);

    useEffect(() => {
        const dataField = fieldToArray(data);
        dataField?.forEach((item) => {
            dispatch(setDataPressItem([item.key, item.value]));
        });
    }, [data, dispatch]);

    const handleChangeInput = (field, value) => {
        dispatch(setDataPressItem([field, value]))
    };

    const saveObject = () => {
        let hasError = false;

        dataOption.forEach((item) => {
            if (item.value.required && (!reportData[item.key] || reportData[item.key].length === 0)) {
                hasError = true;
            }
        });

        if (hasError) {
            setIsErr("Заполните все обязательные поля.");
        } else {
            geoobjectReport.create({ body: reportData }).then((resp) => {
                if (resp.success) {
                    dispatch(clearDataMicrodist());
                    history.push('/geoobjects');
                } else {
                    setIsErr(resp.message);
                }
            }).catch((item) => {
                setIsErr(item.message);
            });
        }
    }
    const updateObject = () => {
        let hasError = false;

        dataOption.forEach((item) => {
            if (item.value.required && (!reportData[item.key] || reportData[item.key].length === 0)) {
                hasError = true;
            }
        });

        if (hasError) {
            setIsErr("Заполните все обязательные поля.");
        } else {
            geoobjectReport.update({ id: `${id}/`, body: reportData }).then((resp) => {
                if (resp.success) {
                    dispatch(clearDataMicrodist());
                    history.push('/geoobjects');
                } else {
                    setIsErr(resp.message);
                }
            }).catch((item) => {
                setIsErr(item.message);
            });
        }
    }



    useEffect(() => {
        if (id === undefined) {
            dispatch(clearDataMicrodist());
        }
    }, [id]);


    const dataFields = {
        user_created: ['user'],
    };

    const renderedKeys = [
        ...dataFields.user_created,
    ];
    // const classMappings = {
    //     'gas_supply_building_status': 'col-1',
    //     'power_supply_building_status': 'col-1',
    //     'water_supply_building_status': 'col-1',
    //     'road_surface_building_status': 'col-1',
    //     'gas_supply_date_end': 'col-2',
    //     'power_supply_date_end': 'col-2',
    //     'water_supply_date_end': 'col-2',
    //     'road_surface_date_end': 'col-2',
    //     'commercial_object': 'col-3',
    //     'social_object': 'col-3',
    // };

    const classContin = {
        'date_start': 'col-1',
        'date_end': 'col-2',
    };

    return (
        <Fragment>
            {openModal && (id ? reportData.inform_tab : reportData) &&
                <ModalMap
                    onClose={() => setOpenModal(false)}
                    titleHeader={reportData.micro_district}
                    subTitleHeader={reportData.micro_district}
                    regionData={reportData.micro_district}
                    activeLandploat={false}
                    landplot={false}
                    isRedact={isRedact}
                    isCreateObject={true}
                />
            }
            <div className="continerPage">
                {
                    isErr &&
                    <ErrorMessage
                        message={isErr}
                        onClose={() => setIsErr(false)}
                        type={'error'}
                        onClick={() => {
                            saveObject()
                        }}
                    />
                }


                <div className="inputContainerCol">
                    {dataOption.map((item, index) => {
                        const key = item.key;
                        const value = item.value;

                        if (key === 'id' || key === 'coordinates' || renderedKeys.includes(key)) {
                            return null;
                        }
                        renderedKeys.push(key);
                        return (
                            <div className={classContin[key] || 'col-3'} key={index}>

                                <div className={classContin[key] || 'col-3'}>

                                    <div>
                                        {key === 'images' ?
                                            <>
                                                <h1>{item.value.label}</h1>
                                                <ImageThumbnail
                                                    imageData={!id ? reportData[item.key] : reportData[item.key]}
                                                    upload={id ? reportData[item.key] : false}
                                                    modal={true}
                                                />
                                                <FilePicker
                                                    type={'image'}
                                                    dataFile={''}
                                                    setFiles={(e) => { handleChangeInput(key, e) }}
                                                />
                                            </>

                                            :
                                            <>
                                                <FormInput
                                                    subInput={key === 'fields' ? `Поле ${index}` : value.label}
                                                    type={value.type}
                                                    options={value.choices}
                                                    keyData={key}
                                                    onChange={(e) => handleChangeInput(key, e)}
                                                    required={value.required}
                                                    key={index}
                                                    value={reportData[key]}
                                                    error={isErr}
                                                />
                                                {key === 'micro_district' &&
                                                    <Buttons
                                                        text={'Добавить координаты'}
                                                        className={'addCoordinats'}
                                                        onClick={() => {
                                                            setOpenModal(true)
                                                        }}
                                                    />
                                                }
                                            </>
                                        }


                                    </div>

                                </div>

                            </div>

                        );
                    })}

                </div>

                {
                    isRedact &&
                    <Buttons
                        text={'Сохранить'}
                        className={'saveButton'}
                        ico={icons.floppyCheckmark}
                        onClick={() => {
                            id ? updateObject() : saveObject()
                        }}
                    />
                }
            </div>
        </Fragment>
    );
};

export default GeoObjectContain;
