import BaseModelAPI from "../BaseModelAPI";
import apiConfig from "../apiConfig";
import axiosClient from "../axiosClient";
import { API_CONTRAGENT_MODEL } from "./const";

class ContragentApiRequest extends BaseModelAPI {
    constructor() {
        super(API_CONTRAGENT_MODEL.url, apiConfig.baseUrlContragent);
    }

    async InfoContragent(id) {
        return this.makeRequest(axiosClient.get, { id: id, method: API_CONTRAGENT_MODEL.methods.info.url });
    }
    async NextContragent(url) {
        return this.makeRequest(axiosClient.get, url && {urlParams: "?" + url.split('?')[1] });
    }
    async searchContragent(dataSearch) {
        return this.makeRequest(axiosClient.get, {urlParams: `?search=${dataSearch ? dataSearch : ''}` });
    }
}

export default ContragentApiRequest;
