import React, { Fragment, useEffect, useMemo, useState } from 'react'
import Header from '../../component/Header/Header'
import icons from '../../assets/icons/icons'
import Tables from '../../component/Tables/Tables'
import { fieldToArray } from "../../component/UI/functions/functions";
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../store/loadingReducer';
import Loader from '../../component/Loader/Loader';
import IntegrationApiRequest from '../../api/Integration/Integration';
import { FileUpload } from 'primereact/fileupload';
import apiConfig from '../../api/apiConfig';
import IntegrationComponent from '../../component/IntegrationComponent/IntegrationComponent';


const IntegrationTablePage = () => {



    const [listIntegration, setListIntegration] = useState(
        [
            {
                id: 1,
                name: 'Бухгалтерия оплата (ручная загрузка)',
                link: 'landplots/import-payments/'
            }
        ]
    )




    return (
        <>
            <div>
                <Header
                    name={'Интеграции'}
                    icon={icons.shuffle}
                    buttonContent={
                        <>

                        </>
                    }
                />
                {listIntegration.map((item) => {
                    return (
                        <IntegrationComponent item={item} />
                    )
                })}
            </div>
        </>

    )
}

export default IntegrationTablePage