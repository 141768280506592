import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import groupUser from '../../locales/groupUser';
import routesConfig from '../routesConfig';

//Тут особо ничего можно не менять (только расширять при необходимости), автоматически строится роутинг в зависимости от указанных групп

const AllRouting = ({ isAuthenticated }) => {
  const userData = useSelector(state => state.user_data.userData);
  const userGroups = userData?.user?.groups?.map(group => group.name) || [];
  const hasAccess = userGroups.some(group => routesConfig[group]);

  if (!hasAccess) {
    return <Redirect to='/error' />;
  }

  const combinedRoutes = userGroups.reduce((acc, group) => {
    if (routesConfig[group]) {
      acc.push(...routesConfig[group].routes, ...routesConfig['BaseGroup'].routes);
    }
    return acc;
  }, []);

  return (
    <Switch>
      {combinedRoutes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          render={(props) => <route.component {...props} {...route.props} />}
        />
      ))}
      <Redirect to='/error' />
    </Switch>
  );
};

export default AllRouting;
