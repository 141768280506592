import BaseModelAPI from "../BaseModelAPI";
import apiConfig from "../apiConfig";
import axiosClient from "../axiosClient";
import { API_CLIENTS_MODEL } from "./const";

class ClientsApiRequest extends BaseModelAPI {
    constructor() {
        super(API_CLIENTS_MODEL.url, apiConfig.baseUrlContragent);
    }

    async uploadImage(data) {
        return this.makeRequest(axiosClient.post, { method: API_CLIENTS_MODEL.methods.uploadImage.url, body: data });
    }
    async stagesSetComplete(id) {
        return this.makeRequest(axiosClient.post, { id: id, method: API_CLIENTS_MODEL.methods.stagesSetComplete.url });
    }
    async createInspection(id, data) {
        return this.makeRequest(axiosClient.post, { id: id, method: API_CLIENTS_MODEL.methods.createInspection.url, body: data });
    }
    async dischargeLandploats(data) {
        return this.makeRequest(axiosClient.post, { method: API_CLIENTS_MODEL.methods.discharge.url, body: data });
    }
}

export default ClientsApiRequest;
