import React, { useEffect, useRef, useState } from 'react';
import './styles.scss';
import icons from '../../../assets/icons/icons';
import { formatBytes } from '../functions/formatBytes/formatBytes';
import Buttons from '../../Buttons/Buttons';
import { getDateTime, getFormatedDate } from '../functions/functions';
import LandPlotApiRequest from '../../../api/Landplot/LandPlot';
import apiConfig from '../../../api/apiConfig';

// Объект сопоставления расширений файлов и их типов
const fileTypes = {
    pdf: 'PDF',
    doc: 'DOC',
    docx: 'DOCX',
    xlsx: 'XLSX',
    txt: 'TXT',
    jpg: 'JPEG',
    png: 'PNG',
    svg: 'SVG',
};

const getFileType = (fileName) => {
    const fileExtension = fileName.split('.').pop().toLowerCase();
    return fileTypes[fileExtension] || 'Unknown Type';
};

const FileAttached = ({ filesData, onDelete, setFiles, addFile, oldUpload }) => {
    const fileInputRef = useRef(null);
    const landPlot = new LandPlotApiRequest();
    const [fileData, setFileData] = useState(filesData)

    useEffect(() => {
        setFileData(filesData)
    }, [filesData])

    const handleFileInputChange = async (e) => {
        const selectedFiles = e.target.files;
        if (selectedFiles.length > 0) {
            const formData = new FormData();

            Array.from(selectedFiles).forEach((file) => {
                formData.append('file', file);
            });
            try {
                const resp = await landPlot.uploadImage(formData);
                if (resp.success) {
                    const mergedFiles = filesData != null ? filesData?.concat(resp.data) : resp.data;
                    const new_files_arr = []
                    for (let i = 0; i < mergedFiles.length; i++) {
                        const new_file = { ...mergedFiles[i] }
                        if (new_file.url)
                            new_file['file'] = new_file.url
                        new_files_arr.push(new_file)
                    }
                    setFiles(new_files_arr);
                    setFileData(new_files_arr);
                } else {
                    console.error('File upload failed:', resp.error);
                }
            } catch (error) {
                console.error('Error uploading files:', error);
            }
        }
    };

    const handleAddFileClick = () => {
        // Trigger the file input click event when the "Добавить файл" button is clicked
        fileInputRef.current.click();
    };

    return (
        <>
            <input
                type="file"
                style={{ display: 'none' }}
                ref={fileInputRef}
                onChange={handleFileInputChange}
                multiple
            />
            {fileData?.map((item, index) => {
                const filePathParts = oldUpload
                    ? (item?.file?.split('/') || item?.file?.split("."))
                    : (item?.name?.split('/') || item?.path?.split(".") || item?.file?.split('/'));

                const fileName = oldUpload ? filePathParts[2] : filePathParts?.[filePathParts?.length - 1];
                const fileType = getFileType(item.original_name || fileName);

                return (
                    // oldUpload?
                    //     item?.file?.map((itemFile)=>{
                    //         return (
                    //             <div className="fileContainer" key={index}>
                    //                 <div className="file__name-wrap">
                    //                     <img src={icons.file} className="file__icon" alt="file icon" />
                    //                     <div className='containerType'>
                    //                         <p className={'file__name'}>{fileName}</p>
                    //                         <div className='file_subText'>
                    //                             <span className="file__type">{fileType}</span>
                    //                         </div>
                    //                     </div>
                    //                 </div>
                    //                 <span className="file__trash" onClick={() => onDelete(itemFile)}>
                    //                     <img src={icons.TrashOne} alt="delete" />
                    //                 </span>
                    //             </div>
                    //         )
                    //    })

                    // :

                    <div className="fileContainer" key={index}>
                        <div className="file__name-wrap">
                            <img src={icons.file} className="file__icon" alt="file icon" />
                            <div className='containerType'>
                                <a href={`${apiConfig.baseUrlMedia}media/${item.original_name || fileName}`} className={'file__name'}>{item.original_name || fileName}</a>
                                <div className='file_subText'>
                                    {item.size && <span className="file__size">{formatBytes(item.size)}</span>}
                                    {item.date ? <span className="file__size">{getDateTime(item.date)}</span> :
                                        (item.uploaded_at && <span className="file__size">{getDateTime(item.uploaded_at)}</span>)}
                                    <span className="file__type">{oldUpload ? fileType : item.extension}</span>
                                </div>
                            </div>
                        </div>
                        <span className="file__trash" onClick={() => onDelete(item)}>
                            <img src={icons.TrashOne} alt="delete" />
                        </span>
                    </div>

                );
            })}
            {addFile && <Buttons text={'Добавить файл'} onClick={handleAddFileClick} className={'buttonAddDocs'} />}
        </>
    );
}

export default FileAttached;
