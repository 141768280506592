import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    updateSidebar: JSON.parse(localStorage.getItem('sidebarState')),
};

const updateSidebar = createSlice({
    name: 'updateSidebar',
    initialState,
    reducers: {
        setUpdateSidebar: (state, action) => {
            state.updateSidebar = action.payload;
        },
    },
});

export const { setUpdateSidebar } = updateSidebar.actions;

export default updateSidebar.reducer;