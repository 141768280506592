const apiConfig = {
    baseUrl: process.env.REACT_APP_BASE_URL,
    baseUrlContragent: process.env.REACT_APP_BASE_URL_CONTRAGENT,
    baseUrlMedia: process.env.REACT_APP_BASE_URL_MEDIA,
    baseUrlUser: process.env.REACT_APP_BASE_URL_USER,
    baseUrlWSS: process.env.REACT_APP_BASE_URL_WSS,
    urlCollective: process.env.REACT_APP_URL_COLLECTIVE,
    urlGeo: process.env.REACT_APP_URL_GEO,
}

export default apiConfig;
