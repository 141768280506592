import BaseModelAPI from "../BaseModelAPI";
import apiConfig from "../apiConfig";
import axiosClient from "../axiosClient";
import { API_USER_MODEL } from "./const";

class UserApiRequest extends BaseModelAPI {
    constructor() {
        super(API_USER_MODEL.url, apiConfig.baseUrlUser);
    }
    
    async nexpPage(url) {
        return this.makeRequest(axiosClient.get, url && {urlParams: "?" + url.split('?')[1] });
    }
    async search(dataSearch) {
        return this.makeRequest(axiosClient.get, {urlParams: `?search=${dataSearch ? dataSearch : ''}` });
    }
}

export default UserApiRequest;
