import React, { useEffect, useState } from 'react';
import Header from '../../component/Header/Header';
import icons from '../../assets/icons/icons';
import FormInput from '../../component/FormInput/FormInput';
import MicrodistrictContain from '../../component/MicrodistrictContain/MicrodistrictContain';
import { useLocation } from 'react-router-dom';
import HeaderInfo from '../../component/HeaderInfo/HeaderInfo';
import { useSelector } from 'react-redux';
import groupUser from '../../locales/groupUser';

const MicrodistrictPage = () => {

    const location = useLocation();
    const body = location.state || {};


    const userData = useSelector(state => state.user_data.userData);

    const isAdmin = userData?.user?.groups?.some(group => group.name === groupUser.admin);
    const isCadastral = userData?.user?.groups?.some(group => group.name === groupUser.cadastralGroup);
    const isControlBuild = userData?.user?.groups?.some(group => group.name === groupUser.controlBuild);
    const isPaymentDepartment = userData?.user?.groups?.some(group => group.name === groupUser.PaymentDeportament);
    const isExploitation = userData?.user?.groups?.some(group => group.name === groupUser.Exploitation);
    const isAuditor = userData?.user?.groups?.some(group => group.name === groupUser.auditor);

    return (
        <div>
            <HeaderInfo icon={icons.Arrow} name={'Добавление микрорайона'} />
            <MicrodistrictContain data={body ? body : undefined} isRedact={isAdmin || isCadastral || isExploitation} isDontRedact={isAuditor} />
        </div>
    );
};

export default MicrodistrictPage;
