import React, { useEffect, useState } from 'react';
import LandploatForm from '../../component/LandploatForm/LandploatForm';
import { useLocation, useParams } from 'react-router-dom';
import { fieldToArray } from '../../component/UI/functions/functions';
import LandPlotApiRequest from '../../api/Landplot/LandPlot';
import { setDataPressItem } from '../../store/microdistDataReduces';
import { useDispatch } from 'react-redux';
import './styles.scss'
import icons from '../../assets/icons/icons';
import HeaderInfo from '../../component/HeaderInfo/HeaderInfo';

const LandplotPage = () => {
    const location = useLocation();
    const body = location.state || {};
    const landPlot = new LandPlotApiRequest();
    const dispatch = useDispatch();

    const [dataOption, setDataOptions] = useState();


    useEffect(() => {
        landPlot.options().then((resp) => {
            const options = fieldToArray(resp.data.actions.create);
            setDataOptions(options);
        });
    }, []);

    useEffect(() => {
        const dataField = fieldToArray(body);
        dataField?.map((item) => {
            dispatch(setDataPressItem([item.key, item.value]));
        });
    }, [body]);

    return (
        <div>
            <HeaderInfo icon={icons.Arrow} name={'Добавление участка'} />
            <div className='formContainer'>
                <LandploatForm options={dataOption} />
            </div>
        </div>
    );
};

export default LandplotPage;
