import React, { useEffect, useState } from 'react';
import './styles.scss';
import MapComponents from '../MapComponents/MapComponents';
import MicroDistrictApiRequest from '../../api/microDistrict/MicroDistrict';
import DefaultMapFilter from '../DefaultMapFilter/DefaultMapFilter';

const ModalMap = ({ onClose, titleHeader, subTitleHeader, regionData, landplot, activeLandploat, isRedact, isCreateMicrodist, isCreateObject }) => {
  const micropdistPlotInstance = new MicroDistrictApiRequest();

  const [coordinatesItem, setCoordinatesItem] = useState([]);
  const [districtData, setDistrictData] = useState();
  const [isActiveLandploat, setIsActiiveLandploat] = useState();

  useEffect(() => {
    // Заблокировать прокрутку при открытии модального окна
    document.body.style.overflow = 'hidden';

    // Вернуть нормальную прокрутку при закрытии модального окна
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);




  // useEffect(() => {
  //   if (regionData != undefined) {
  //     micropdistPlotInstance.mapApi(regionData, '?ignore_filters')
  //       .then((resp) => {
  //         if (resp.success) {
  //           const reversedCoordinates = resp.data.coordinates.map((item) => {
  //             return item
  //           }
  //           );
  //           setDistrictData(resp.data)

  //           resp.data.land_plots.map((item) => {

  //             if (item?.cadastral === activeLandploat?.cadastral) {
  //               setIsActiiveLandploat(item)
  //             }
  //           })

  //           setCoordinatesItem(reversedCoordinates);
  //         }
  //       })
  //       .catch((error) => {
  //         console.error('Ошибка при получении данных:', error);
  //       });
  //   }
  // }, []);

  const setMapData = (data) => {
    const reversedCoordinates = data.coordinates.map((item) => (item));
    setDistrictData(data)
    data.land_plots.map((item) => {
      if (item?.cadastral === activeLandploat?.cadastral) {
        setIsActiiveLandploat(item)
      }
    })
    setCoordinatesItem(reversedCoordinates);
  }

  return (
    <div>
      <div className="modalContainerBg" onClick={onClose}></div>
      <div className="modalContainer">
        <div className="mapHeader">
          <h1>{titleHeader ? titleHeader : districtData?.name}</h1>
          <h3>{subTitleHeader}</h3>
        </div>
        {/* {districtData?.land_plots ? (
            <MapComponents
              coordinats={coordinatesItem}
              styleContainer="modalMap"
              mapStyle="modalMapStyle"
              modal={true}
              onClose={onClose}
              landplot={districtData?.land_plots}
              activeLandploat={isActiveLandploat}
            />
          ) : ( */}
        {isCreateMicrodist ? <MapComponents
          coordinats={coordinatesItem}
          styleContainer="modalMap"
          mapStyle="modalMapStyle"
          modal={true}
          isRedact={true}
          onClose={onClose}
          landplot={districtData?.land_plots}
          activeLandploat={isActiveLandploat}
          isCreateObject={isCreateObject}
        />
          : districtData?.land_plots ? <MapComponents
            coordinats={coordinatesItem}
            styleContainer="modalMap"
            mapStyle="modalMapStyle"
            modal={true}
            onClose={onClose}
            landplot={districtData?.land_plots}
            activeLandploat={isActiveLandploat && isActiveLandploat}
            isRedact={isRedact}
            isCreateObject={isCreateObject}
          />
            :
            <p>Загрузка</p>
        }
        {/* )} */}
        <DefaultMapFilter setMapData={setMapData} />
      </div>
    </div>

  )
}

export default ModalMap