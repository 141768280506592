import React, { useEffect, useMemo, useState } from 'react';
import Tabs from '../../component/Tabs/Tabs';
import Header from '../../component/Header/Header';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LandPlotApiRequest from '../../api/Landplot/LandPlot';
import { fieldToArray } from '../../component/UI/functions/functions';
import { setDataPressItem } from '../../store/microdistDataReduces';
import LandploatForm from '../../component/LandploatForm/LandploatForm';
import LandploatFormContragent from '../../component/LandploatFormContragent/LandploatFormContragent';
import icons from '../../assets/icons/icons';
import HeaderInfo from '../../component/HeaderInfo/HeaderInfo';
import LandploatFormStages from '../../component/LandploatFormStages/LandploatFormStages';
import LandploatFormHistory from '../../component/LandploatFormHistory/LandploatFormHistory';
import LandploatFromPayment from '../../component/LandploatFromPayment/LandploatFromPayment';
import LandploatFormComments from '../../component/LandploatFormComments/LandploatFormComments';
import groupUser from '../../locales/groupUser';
import Loading from '../../component/Loader/Loader'
import LandploatFormPris from '../../component/LandploatFormPris/LandploatFormPris';
import Loader from '../../component/Loader/Loader';
import LandploatFromControl from '../../component/LandploatFromControl/LandploatFromControl';

const LandplotPageUpdate = ({ type, toBack = '' }) => {

    const userData = useSelector(state => state.user_data.userData);

    const isAdmin = userData?.user?.groups?.some(group => group.name === groupUser.admin);
    const isCadastral = userData?.user?.groups?.some(group => group.name === groupUser.cadastralGroup);
    const isControlBuild = userData?.user?.groups?.some(group => group.name === groupUser.controlBuild);
    const isControlBuildTabSaveBtn = userData?.user?.groups?.some(group => group.name === groupUser.controlBuildTabSaveBtn);
    const isPirsTabSaveBtn = userData?.user?.groups?.some(group => group.name === groupUser.pirsTabSaveBtn);
    const isPaymentDepartment = userData?.user?.groups?.some(group => group.name === groupUser.PaymentDeportament);
    const isExploitation = userData?.user?.groups?.some(group => group.name === groupUser.Exploitation);
    const isAuditor = userData?.user?.groups?.some(group => group.name === groupUser.auditor);

    const landPlot = new LandPlotApiRequest();
    const dispatch = useDispatch();
    const location = useLocation()
        , history = useHistory()
        , params = new URLSearchParams(location.search)
    const [body, setBody] = useState(location.state || {});
    const [tabsOption, setTabsOption] = useState();
    const [activeTab, setActiveTab] = useState(params.has('tab') ? params.get('tab') : 'inform_tab'); // Устанавливаем активный таб по умолчанию
    const microdistData = useSelector((state) => state.mocrodist_data);
    const needsRerender = useSelector((state) => state.needsRerender);
    const { id } = useParams()

    useEffect(() => {
        async function fetchData() {
            const resp = await landPlot.options();
            setTabsOption(resp.data.actions.tabs);
        }
        landPlot.getById({ urlParams: id }).then((resp) => {
            if (resp.success) {
                setBody(resp.data)
            }
        });
        fetchData();

    }, [needsRerender])

    useEffect(() => {
        const { inform_tab, contragent_tab, stages_tab, control_building_tab, pris_tab, history_tab, payment_tab, comments_tab } = body;

        dispatch(setDataPressItem(['inform_tab', inform_tab]));
        dispatch(setDataPressItem(['contragent_tab', contragent_tab]));
        dispatch(setDataPressItem(['stages_tab', stages_tab]));
        dispatch(setDataPressItem(['control_building_tab', control_building_tab]));
        dispatch(setDataPressItem(['pris_tab', pris_tab]));
        dispatch(setDataPressItem(['history_tab', history_tab]));
        dispatch(setDataPressItem(['payment_tab', payment_tab]));
        dispatch(setDataPressItem(['comments_tab', comments_tab]));

    }, [body, dispatch]);


    const keys = fieldToArray(tabsOption)?.map((item) => item.key);
    const tabsData = [
        {
            id: 'inform_tab',
            text: 'Информация',
            content: keys.includes('inform_tab') ? (

                <LandploatForm data={microdistData.inform_tab} options={fieldToArray(tabsOption?.inform_tab.children)} type={type} isRedact={isAdmin || isCadastral || isPaymentDepartment || isExploitation} isDontRedact={isControlBuild} />
            ) : null,
        },
        {
            id: 'contragent_tab',
            text: 'Собственник',
            content: keys.includes('contragent_tab') ? (
                <LandploatFormContragent
                    data={microdistData.contragent_tab}
                    optionsContragent={fieldToArray(tabsOption?.contragent_tab.children.contragent.children)}
                    optionsContract={fieldToArray(tabsOption?.contragent_tab.children.contract.children)}
                    type={type} isRedact={isAdmin || isPaymentDepartment || isControlBuild}
                />
            ) : null,
        },
        // Проверяем, что contragent_tab не пустой объект
        ...(body?.contragent_tab?.contragent?.id != (null || undefined) && Object.keys(body.contragent_tab)?.length > 0
            ? [
                {
                    id: 'stages_tab',
                    text: 'Стадии',
                    content: keys.includes('stages_tab') ? (
                        <LandploatFormStages stagesData={microdistData.stages_tab} stagesOption={tabsOption.stages_tab} isRedact={isAdmin || isCadastral || isControlBuild} />
                    ) : null,
                }
            ]
            : []),
        ...(Object.keys(body?.control_building_tab || {})?.length > 0
            ? [
                {
                    id: 'control_building_tab',
                    text: 'КС',
                    content: keys.includes('control_building_tab') ? (
                        <LandploatFromControl
                            controlData={microdistData.control_building_tab}
                            controlOptionBonusDocDate={tabsOption?.control_building_tab?.children?.bonus_doc_date}
                            controlOptionComplaint={fieldToArray(tabsOption?.control_building_tab?.children?.complaint?.children)}
                            controlOptionFoundation={fieldToArray(tabsOption?.control_building_tab?.children?.foundation?.children)}
                            controlOptionExploitationt={fieldToArray(tabsOption?.control_building_tab?.children?.exploitation?.children)}
                            type={type} isRedact={isAdmin || isControlBuildTabSaveBtn}
                        // isDontRedact={isExploitation || isControlBuild}
                        />
                    ) : <></>,
                },
            ]
            : []),
        ...(body?.contragent_tab?.contragent?.id != (null || undefined) && Object.keys(body.contragent_tab).length > 0
            ? [
                {
                    id: 'pris_tab',
                    text: 'ПИРС',
                    content: keys.includes('pris_tab') ? (
                        <LandploatFormPris
                            stagesData={microdistData.pris_tab}
                            optionsАgreements={fieldToArray(tabsOption?.pris_tab.children.agreements.child.children)}
                            optionsClaim={fieldToArray(tabsOption?.pris_tab.children.claims.child.children)}
                            optionsCourtCases={fieldToArray(tabsOption?.pris_tab.children.court_cases.child.children)}
                            isRedact={isAdmin || isPirsTabSaveBtn}
                        />

                    ) : null,
                }
            ]
            : []),
        ...(body?.contragent_tab?.contragent?.id != (null || undefined) && body?.contragent_tab?.contract?.bonus_doc_date != (null || undefined) && Object.keys(body.contragent_tab).length > 0
            ? [
                {
                    id: 'payment_tab',
                    text: 'Оплата',
                    content: keys.includes('payment_tab') ? (
                        <LandploatFromPayment
                            paymentData={microdistData.payment_tab}
                            paymentOptionIntermediate={fieldToArray(tabsOption?.payment_tab?.children?.intermediate_plot_payment?.children)}
                            paymentOptionNewPlot={fieldToArray(tabsOption?.payment_tab?.children?.new_plot_payment?.children)}
                            paymentOptionOldPlot={fieldToArray(tabsOption?.payment_tab?.children?.old_plot_payment?.children)}
                            paymentOptionDefaultPlot={fieldToArray(tabsOption?.payment_tab?.children?.default_plot_payment?.children)}
                            type={type} isRedact={isAdmin || isCadastral || isPaymentDepartment} isDontRedact={isExploitation || isControlBuild}
                        />
                    ) : null,
                },
            ]
            : []),
        {
            id: 'comments_tab',
            text: 'Комментарии',
            content: keys.includes('comments_tab') ? (
                <LandploatFormComments stagesData={microdistData.comments_tab} stagesOption={fieldToArray(tabsOption.comments_tab.children)} isRedact={isAdmin || isCadastral} />
            ) : null,
        },

        ...(type !== 'control'
            ? [
                {
                    id: 'history_tab',
                    text: 'История',
                    content: keys.includes('history_tab') ? (
                        <LandploatFormHistory stagesData={microdistData.history_tab} stagesOption={tabsOption.history_tab} isRedact={isAdmin || isCadastral} />
                    ) : null,
                }
            ]
            : []),

    ];

    const tabSetData = (id) => {
        if (keys.includes(id)) {
            setActiveTab(id);
        }
    }

    useMemo(() => {
        params.append('tab', activeTab)
        history.push(`?tab=${activeTab}`)
        dispatch(setDataPressItem(['tab', activeTab]));
    }, [activeTab])

    // useEffect(() => {
    //     dispatch(setDataPressItem(['tab', 'inform_tab']));
    // }, []);

    return (
        <div>

            {microdistData?.inform_tab ?
                <>
                    <HeaderInfo icon={icons.Arrow} name={`Участок ${body?.inform_tab?.cadastral}`} toBack={toBack} />
                    <Tabs tabsData={tabsData} activeTab={activeTab} setActiveTab={(id) => tabSetData(id)} />
                </>
                :
                <Loader />
            }
        </div>
    );
}

export default LandplotPageUpdate;
