import React, { useState } from 'react';
import './styles.scss'
import Buttons from '../Buttons/Buttons';
import { setNeedsRerender } from '../../store/needsRerenderSlice';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';

const TabContent = ({ content }) => {
    return (
        <div className='infoTextContainer'>
              {content ? content : 
               <div className='continerLandplotPage'>
                <div className='inputContainerCol'>
                    <div className='col-1'>
                        <Skeleton height={40} width={'100%'} count={1} borderRadius={6} />
                    </div>
                    <div className='col-2'>
                        <Skeleton height={40} width={'100%'} count={1} borderRadius={6} />
                    </div>
                    <div className='col-1'>
                        <Skeleton height={40} width={'100%'} count={1} borderRadius={6} />
                    </div>
                    <div className='col-2'>
                        <Skeleton height={40} width={'100%'} count={1} borderRadius={6} />
                    </div>
                    <div className='col-3'>
                        <Skeleton height={40} width={'100%'} count={1} borderRadius={6} />
                    </div>
                    <div className='col-1'>
                        <Skeleton height={40} width={'100%'} count={1} borderRadius={6} />
                    </div>
                    <div className='col-2'>
                        <Skeleton height={40} width={'100%'} count={1} borderRadius={6} />
                    </div>
                    <div className='col-3'>
                        <Skeleton height={40} width={'100%'} count={1} borderRadius={6} />
                    </div>
                    <div className='col-1'>
                        <Skeleton height={40} width={'100%'} count={1} borderRadius={6} />
                    </div>
                    <div className='col-2'>
                        <Skeleton height={40} width={'100%'} count={1} borderRadius={6} />
                    </div>
                </div>
                </div>
              }
        </div>
    );
};

const Tabs = ({ tabsData, activeTab, setActiveTab }) => {

    return (
        <div className='tabRole'>
            <div className='roleContainerButton'>
                {tabsData.map((item) => (
                    <Buttons
                        key={item.id}
                        className={`buttonRole ${item.id === activeTab ? 'active' : ''}`}
                        onClick={() => setActiveTab(item.id)}
                        text={item.text}
                    >

                    </Buttons>
                ))}
            </div>
            {tabsData.map((item) => (
                <div key={item.id}>
                    {item.id === activeTab && <TabContent content={item.content} />}
                </div>
            ))}
        </div>
    );
};

export default Tabs;
